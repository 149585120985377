import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Homepage from "./enduser/pages/Homepage";
import Header from "./enduser/components/header/Header";
import Footer from "./enduser/components/footer/footer";
import Servicelisting from "./enduser/pages/servicelisting";
import DetailServiceListingPage from "./enduser/pages/DetailServiceListingPage";
import ProfilePage from "./enduser/pages/ProfilePage";
import ScrollToTop from "./enduser/components/ScrollToTop/ScrollToTop";
import Terms from "./enduser/components/footer/Terms";
import ContactUs from "./enduser/components/footer/ContactUs";
import Blogs from "./enduser/components/Blogs/Blogs";
import BlogItem from "./enduser/components/Blogs/BlogItem";
import DiscussionList from "./enduser/components/Discussion/DiscussionList";
import Discussion from "./enduser/components/Discussion/Discussion";
import RevaaloBusinessPage from "./enduser/components/footer/RevaaloBusinessPage";
import AboutUs from "./enduser/components/footer/AboutUs";
import AddBusiness from "./enduser/components/footer/AddBusiness";

const AppRouter = (props) => {
  const token = localStorage.getItem("token");

  return (
    <>
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/:city/:slug" element={<Servicelisting />} />
          <Route path="/:slug" element={<Servicelisting />} />
          <Route
            path="/:service/:category/:slug"
            element={<DetailServiceListingPage />}
          />
          {token !== null || undefined || " " ? (
            <Route
              path="/profilepage"
              element={token !== null ? <ProfilePage /> : <Navigate to="/" />}
            />
          ) : null}
          <Route path="/terms-use" element={<Terms />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/revaalo-business" element={<RevaaloBusinessPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/add-business" element={<AddBusiness />} />
          <Route path="/blogs/:slug" element={<BlogItem />} />
          <Route
            path="blogs/category/:slug"
            key="discussion"
            element={<Blogs />}
          />
          <Route path="/discussion" element={<DiscussionList />} />
          <Route
            path="/topic/:slug"
            key="discussion"
            element={<Discussion />}
          />
          <Route
            path="discussion/category/:slug"
            key="discussion"
            element={<DiscussionList />}
          />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default AppRouter;
