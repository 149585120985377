import {
  Button,
  Card,
  Modal,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useNavigate, useParams } from "react-router-dom";
import Search from "../Search/Search";
import "./style.css";
import Axios from "../../../axios";
import axios from "axios";
import Login from "../Login/login";
import Register from "../Register/register";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { NotificationManager } from "react-notifications";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import bimg from "../../assets/blog-dummy.png";
const styleTab = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  overflowX: "hidden",
};
const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const Blogs = () => {
  const [token, setToken] = useState();
  const navigate = useNavigate();

  const [type, setType] = useState("");
  const [openLogin, setOpenLogin] = useState(false);
  const [valueTab, setValueTab] = useState("one");
  const [open, setOpen] = useState(false);
  const [categorylist, setCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const userid = localStorage.getItem("id");
  const [blogList, setBlogList] = useState([]);
  const [pages, setPages] = useState(1);
  const [count, setCount] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [catId, setCatId] = useState("");
  const [totalLikes, setTotalLikes] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [blogThumbnail, setBlogThumbnail] = useState([]);
  const { slug } = useParams();
  const handleNavigate = (slug) => {
    const path = `/${slug}`;
    navigate(path, { state: slug });
  };
  useEffect(() => {
    if (slug) {
      axios
        .post(process.env.REACT_APP_BACKEND + "blogs//listing/categoryblogs", {
          category: slug,
        })
        .then((response) => {
          setBlogList(response.data.data.data);
          setCount(response.data.data.pages);
        });
    } else {
      axios
        .post(process.env.REACT_APP_BACKEND + "blogs/listing")
        .then((response) => {
          setBlogList(response.data.data.data);

          setCount(response.data.data.pages);
        });
    }
    setToken(localStorage.getItem("token"));
    axios
      .get(process.env.REACT_APP_BACKEND + "blogcategory/listing/category")
      .then((response) => {
        // let catergory = response.data.data;
        // let cat = catergory.filter((type) => type.type);
        setCategoryList(response.data.data);
      });
  }, [slug]);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const handleModalOpen = () => {
    if (token !== null && token !== undefined && token !== "") {
      setOpen(true);
      setType("");
      setTitle("");
      setDescription("");
    } else setOpenLogin(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenLogin(false);
    setDeleteModal(false);
  };

  const handleChangeCategory = (event, value) => {
    setCategoryValue(value);
  };
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "title") {
      setTitle(value);
    } else if (name === "short_description") {
      setShortDescription(value);
    } else if (name === "blogThumbnail") {
      let files = Array.prototype.slice.call(e.target.files);
      setBlogThumbnail(files);
    }
  };
  // ------------------------handleChnage for description------------------------------
  const handleChnageQuil = (value) => {
    setDescription(value);
  };

  //---------------------------- Function And Api for Create and EDit of new Blog----------------------------

  const handleSubmitBlogs = () => {
    let data = new FormData();
    data.append("title", title);
    data.append("content", description);
    data.append("user_ref", userid);
    data.append("_id", catId);
    data.append("category_ref", categoryValue?._id);
    data.append("short_description", shortDescription);
    blogThumbnail.map((file) => {
      data.append("thumbnail", file);
    });
    if (type === "edit") {
      Axios.post(process.env.REACT_APP_BACKEND + "blogs/update", data).then(
        (response) => {
          setTitle("");
          setDescription("");
          setCategoryValue("");
          setBlogThumbnail([]);
          setBlogList(response.data.data.data);
          setPages(response.data.data.pages);
          NotificationManager.success(response.data.message);
          handleClose();
        }
      );
    } else {
      if (title !== " " && description !== "" && userid !== "") {
        Axios.post(process.env.REACT_APP_BACKEND + "blogs/create", data).then(
          (response) => {
            setTitle("");
            setDescription("");
            setCategoryValue("");
            setBlogThumbnail([]);
            setBlogList(response.data.data.data);
            setPages(response.data.data.pages);
            NotificationManager.success(response.data.message);
          }
        );
        handleClose();
      } else {
        NotificationManager.warning("Please Select all the required fields");
      }
    }
  };
  //--------------------------------------- Pagenation funcation-------------------------
  const handleChangePage = (e, p) => {
    setPages(p);
    const data = {
      limit: 10,
      page: p,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "blogs/listing", data)
      .then((response) => {
        setBlogList(response.data.data.data);
        setCount(response.data.data.pages);
        window.scroll(0, 0);
      });
  };
  // ---------------------Delete Blog-------------------------------------
  const handleDeleteModal = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModal(true);
    setDeleteId(id);
  };
  const handleDeleteBlog = () => {
    Axios.post(process.env.REACT_APP_BACKEND + "blogs/delete", {
      _id: deleteId,
      user_ref: userid,
    }).then((resp) => {
      setBlogList(resp.data.data.data);
      setCount(resp.data.data.pages);
      NotificationManager.success(resp.data.message);
      handleClose();
    });
  };
  // =-------------------------------Edit Blogs-------------------------------
  const handleEditBlog = (e, alias) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(true);

    axios
      .post(process.env.REACT_APP_BACKEND + `blogs/getone/${alias}`)
      .then((resp) => {
        setCategoryValue(resp?.data?.data?.data?.category_ref);
        setTitle(resp?.data?.data?.data?.title);
        setDescription(resp?.data?.data?.data?.content);
        setCatId(resp?.data?.data?.data?._id);
        setShortDescription(resp?.data?.data?.data?.short_description);
        setType("edit");
      });
  };
  //for category
  const handleClickCategory = (slug, id) => {
    console.log(slug);
    let path = `/${slug}`;
    navigate(path);
    axios
      .post(process.env.REACT_APP_BACKEND + "blogs//listing/categoryblogs", {
        category: id,
      })
      .then((response) => {
        setBlogList(response.data.data.data);
        setCount(response.data.data.pages);
      });
  };
  const handleClickBlogs = () => {
    navigate("/blogs");
    axios
      .post(process.env.REACT_APP_BACKEND + "blogs/listing")
      .then((response) => {
        setBlogList(response.data.data.data);

        setCount(response.data.data.pages);
      });
  };
  return (
    <div>
      <div className="container blog-main-cont">
        {/* <div className="row">
            <Search />
          </div> */}
        <div className="d-flex justify-content-between align-items-center">
          <h1>Blog</h1>
          <Button
            variant="contained"
            className="mb-4"
            onClick={handleModalOpen}
          >
            Create New Blogs
          </Button>
        </div>
        <div>
          <div className="row">
            <div className="col-md-8">
              {blogList.map((blog, index) => (
                <Card
                  className="shadow-sm mb-3 b_card"
                  onClick={() => handleNavigate(blog?.alias)}
                  key={index}
                >
                  <div className="row shadow-md ">
                    <div
                      className="col-md-4 p-0"
                      style={{ alignSelf: "center" }}
                    >
                      <div style={{ position: "relative" }}>
                        <img
                          src={
                            blog?.thumbnail !== null &&
                            blog?.thumbnail !== undefined &&
                            blog?.thumbnail !== ""
                              ? blog?.thumbnail
                              : bimg
                          }
                          alt=""
                          style={{
                            width: "100%",
                            borderRadius: "4px",
                            maxHeight: "180px",
                          }}
                          crossorigin="anonymous"
                        />
                        <p
                          style={{
                            position: "absolute",
                            bottom: "10px",
                            left: "30px",
                            background: "white",
                            borderRadius: "8px",
                            padding: "5px 20px",
                            color: "black",
                            textAlign: "center",
                          }}
                        >
                          {blog?.category_ref?.name}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8 p-2">
                      <div className="d-flex justify-content-between">
                        <p className="blogs_date">
                          {" "}
                          {moment(blog?.createdAt).format("DD MMM YYYY")}
                        </p>
                        {userid === blog?.user_ref?._id ? (
                          <p className="m-0">
                            {" "}
                            <IconButton
                              aria-label="delete"
                              onClick={(e) => handleDeleteModal(e, blog?._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              aria-label="edit"
                              onClick={(e) => handleEditBlog(e, blog?.alias)}
                            >
                              <EditIcon />
                            </IconButton>
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <p className="blogs_title pe-2">{blog?.title}</p>
                      <p className="blogs_descp px-2">
                        {blog?.short_description}
                      </p>
                      {/* <div className="blogs_descp" dangerouslySetInnerHTML={{__html: blog?.content}}></div> */}

                      {/* <p className="blogs_descp">{}</p> */}
                      <div className="d-flex  align-items-end">
                        <p className="bg-secondary p-1 m-0 rounded text-white">
                          Comments {blog?.no_of_comments}
                        </p>
                        <p className="me-2 ms-2 p-1 mt-0 mb-0 bg-secondary rounded text-white">
                          Likes {blog?.no_of_likes}
                        </p>
                        <p className="bg-secondary p-1 m-0 rounded text-white">
                          {" "}
                          Share {blog?.no_of_shares}
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
              <div className="d-flex justify-content-center mt-3">
                <Pagination
                  count={count}
                  page={pages}
                  variant="outlined"
                  onChange={handleChangePage}
                  shape="rounded"
                  color="primary"
                />
              </div>
            </div>
            <div className="col-md-4">
              {/* <Button
                variant="contained"
                fullWidth
                className="mb-4"
                onClick={handleModalOpen}
              >
                Create New Blogs
              </Button> */}
              <h3>Blogs Categories</h3>
              <Card className="p-2 cardbg">
                <div className="col-md-12">
                  <div
                    className=" shadow"
                    style={{
                      height: "300px",
                      overflowY: "scroll",
                      padding: "8px",
                    }}
                  >
                    <div className="p-0">
                      <p className="category_border" onClick={handleClickBlogs}>
                        All Blogs
                      </p>

                      {categorylist.map((item, index) => (
                        <p
                          className={
                            item.alias === slug
                              ? "categoryActive"
                              : "category_border"
                          }
                          onClick={() =>
                            handleClickCategory(item.slug, item.alias)
                          }
                          key={index}
                        >
                          {item.name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </Card>
              <h3 className="mt-4">Tags</h3>
            </div>
          </div>
        </div>
      </div>
      {/*---------------------------------------- Create/EDit Modal-------------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview blog_modal ">
          <div className="text-center p-3">
            <h4 className="mt-2">
              {type === "edit" ? <>Edit </> : <>Create New Blogs </>}
            </h4>

            <div className=" mt-4">
              <p className="text-start">
                <em style={{ color: "red" }}>*</em> Title
              </p>

              <TextField
                label="Title"
                fullWidth
                name="title"
                value={title}
                onChange={handleChange}
              />
            </div>
            <div className=" mt-4">
              <p className="me-2 text-start">
                <em style={{ color: "red" }}>*</em> Category
              </p>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                fullWidth
                freeSolo
                value={
                  categorylist.find(
                    (option) => option?.name === categoryValue?.name
                  ) || null
                }
                options={categorylist}
                getOptionLabel={(productList) =>
                  productList?.name !== null ? productList?.name : <></>
                }
                onChange={handleChangeCategory}
                renderInput={(params) => (
                  <TextField {...params} label=" Select Category" />
                )}
              />
            </div>
            <div className=" mt-4">
              <p className="text-start">
                <em style={{ color: "red" }}>*</em> Upload Images
              </p>

              <Button variant="contained" component="label">
                Upload
                <input
                  hidden
                  accept="image/*"
                  // multiple
                  type="file"
                  name="blogThumbnail"
                  onChange={handleChange}
                />
              </Button>
              {blogThumbnail.map((item, index) => (
                <span className="ms-2" key={index}>
                  {item.name}
                </span>
              ))}
            </div>
            <div className=" mt-4">
              <p className="me-2 text-start">
                <em style={{ color: "red" }}>*</em> Short Description
              </p>
              <div>
                <textarea
                  style={{
                    resize: "none",
                    outline: "none",
                    width: "100%",
                    height: "auto",
                  }}
                  value={shortDescription}
                  name="short_description"
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className=" mt-4">
                <p className="me-2 text-start">
                  <em style={{ color: "red" }}>*</em> Description
                </p>
                <ReactQuill
                  theme="snow"
                  value={description}
                  onChange={handleChnageQuil}
                  formats={formats}
                  modules={modules}
                  bounds={".rq"}
                  placeholder="Write Here"
                />
              </div>
            </div>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "90%", mt: 4 }}
                onClick={handleSubmitBlogs}
              >
                {type === "edit" ? <>Submit</> : <> Create New Blogs</>}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/*----------------------------------- LoginModal-------------------------------------- */}
      <Modal
        open={openLogin}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTab}>
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label="Login" value="one" />
                <Tab label="Register" value="two" />
                <Button
                  onClick={handleClose}
                  style={{ marginLeft: "15rem", color: "grey" }}
                >
                  <CloseIcon />
                </Button>
              </TabList>
            </Box>
            <TabPanel value="one">
              <Login close={handleClose} />
            </TabPanel>
            <TabPanel value="two">
              <Register close={handleClose} />
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
      {/* ------------------Delete Modal-------------------------------------------- */}
      <Modal
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">Are you Sure you want to delete the Blog?</h4>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "40%", mt: 4, mr: 4 }}
                onClick={handleDeleteBlog}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "40%", mt: 4 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Blogs;
