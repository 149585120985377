import React from "react";
import "./style.css";
import revaalo from "../../assets/revaalo2.png";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";

import Typography from "@mui/material/Typography";

import IconButton from "@mui/material/IconButton";

const Footer = () => {
  return (
    <>
      <div className="fotBody container-fluid">
        <div className="row">
          <div className="col-md-6 text-center p-2">
            <Link
              to="/"
              className="d-flex justify-content-center align-items-center"
              style={{ textDecoration: "none", color: "#FFFFFF" }}
            >
              <img
                src={revaalo}
                alt="logo"
                style={{ width: "65px", height: "45px" }}
              />
              <span style={{ fontWeight: 700, fontSize: "30px" }}>Revaalo</span>
            </Link>
          </div>

          <div className="col-md-6 text-center p-2">
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              href="https://www.facebook.com/revaalobengaluru"
            >
              <FacebookSharpIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <GoogleIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              href="https://twitter.com/Revaalo"
            >
              <TwitterIcon />
            </IconButton>
          </div>
        </div>
        <hr />

        <div className="row text-center">
          <div className="col-md-3">
            <h5>Links</h5>
            <p>
              <Link to="/add-business" className="footer_link">
                Add your Business
              </Link>{" "}
            </p>
            <p>
              <Link to="/" className="footer_link">
                Products
              </Link>{" "}
            </p>
            <p>
              <Link to="/" className="footer_link">
                Products Services
              </Link>{" "}
            </p>
          </div>
          <div className="col-md-3">
            <h5>Revaalo</h5>
            <p>
              <Link to="/about-us" className="footer_link">
                About Us
              </Link>{" "}
            </p>
            <p>
              <Link to="/contact-us" className="footer_link">
                Contact Us
              </Link>{" "}
            </p>
            <p>
              {" "}
              <Link to="/revaalo-business" className="footer_link">
                Revaalo for Business
              </Link>{" "}
            </p>
          </div>
          <div className="col-md-3">
            <h5>Revaalo IT</h5>
            <p>
              {" "}
              <a href="https://revaalolabs.com/" className="footer_link">
                Powered by RevaaloLabs{" "}
              </a>
            </p>
          </div>
          <div className="col-md-3">
            <h5>Others</h5>
            <p>
              <Link to="/terms-use" className="footer_link">
                Terms of use{" "}
              </Link>
            </p>
            <p>
              <Link to="/" className="footer_link">
                Sitemaps
              </Link>
            </p>
          </div>
        </div>

        {/* </div>
        <div className='fotBody'>

        </div> */}
        <hr />
        <Typography sx={{ textAlign: "center" }}>
          {" "}
          Copyrights@2022-2023 revaalo
        </Typography>
      </div>
    </>
  );
};

export default Footer;
