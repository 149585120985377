import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, Button } from "@mui/material";
import CatergoryList from "../categorylist/categoryList.jsx";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import CountUp from "react-countup";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import RecentReviews from "./RecentReviews";
const Home = () => {
  const [city, setCity] = useState([]);
  const [productList, setProductList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inpPrValue, setInpPrValue] = useState("");
  const [valueProduct, setInputProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [cityName, setCityName] = useState("");
  const [type, setType] = useState("");
  const [proOpen, setProOpen] = useState(false);
  const [slug, setSlug] = useState("");
  const [alias, setAlias] = useState("");
  const [cityObject, setCityObject] = useState([]);
  const [diffType, setdiffType] = useState("");
  const [show, setShow] = useState(false);
  const [topCityList, setTopCityList] = useState([]);
  const [cityList, setcityList] = useState([]);
  let navigate = useNavigate();
  const localCity = localStorage.getItem("city");

  useEffect(() => {
    if (localCity !== null && localCity !== undefined && localCity !== "") {
      setShow(false);
      setInputValue(localCity);
      setCityName(localCity);
    } else {
      setShow(true);

      axios
        .get(process.env.REACT_APP_BACKEND + "city/read")
        .then((res) => {
          setcityList(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  const handleCity = (value) => {
    axios
      .post(process.env.REACT_APP_BACKEND + "search/city", { cityArea: value })
      .then((res) => {
        setCity(res.data);
      });
  };
  const handleproduct = (event, value) => {
    setInpPrValue(value);
    // console.log(value);

    axios
      .post(process.env.REACT_APP_BACKEND + "search/productsandservices", {
        category: value,
      })
      .then((res) => {
        setProductList(res.data.data);
      });
  };
  const handleSubmit = () => {
    if (diffType == "product") {
      let path = `${slug}`;
      navigate(path);
    } else if (diffType === "service") {
      let path = `${cityName}/${slug}`;
      navigate(path, { state: cityObject });
    } else if (diffType === "singleService") {
      let path = slug;
      navigate(path);
    } else if (diffType === "singleProduct") {
      let path = slug;
      navigate(path);
    } else {
      // let path = `${cityName}/search`;
      let path = `${cityName}`;
      navigate(path, { state: { city: cityObject, inp: inpPrValue } });
    }
  };

  const handleproductChange = (event, value) => {
    setInputProduct(value);

    if (value.difftype == "product") {
      setType(value.type);
      setSlug(value.slug);
      setdiffType(value.difftype);
      let path = `${value.slug}`;
      navigate(path);
    } else if (value.difftype === "service") {
      setSlug(value.slug);
      setdiffType(value.difftype);
      let path = `${cityName}/${value.slug}`;
      navigate(path, { state: cityObject });
    } else if (value.difftype === "singleService") {
      setType(
        value &&
          value.serviceCategory &&
          value.serviceCategory[0] &&
          value.serviceCategory[0].type
      );
      setSlug(
        value &&
          value.serviceCategory &&
          value.serviceCategory[0] &&
          value.serviceCategory[0].slug
      );
      setAlias(value && value.alias);
      setdiffType(value.difftype);
      let path = value.slug;
      navigate(path);
    } else if (value.difftype === "singleProduct") {
      setSlug(value.slug);
      setdiffType(value.difftype);
      let path = value.slug;
      navigate(path);
    } else {
    }
  };
  const handleCityChange = (event, value) => {
    setCityName(value.city_name);
    setCityObject(value);
  };
  const handleClose = () => {
    setInputValue("Bangalore");
    setCityName("bangalore");
    localStorage.setItem("city", "bangalore");
    setShow(false);
  };
  const handlecitySubmit = (val) => {
    setInputValue(val.city_name);
    setCityObject(val);
    setCityName(val.city_name);
    localStorage.setItem("city", val.city_name);
    handleClose();
  };
  // const handlekeypress = (val) => {
  //   console.log(val);
  // };

  return (
    <>
      <div className="homebg">
        <div className="home_gradient">
          <div className="home_body">
            <div className="  mb-5 p-0 w-50 ms-0 banner_title">
              <h2>
                Find the best{" "}
                <span style={{ color: "#01a1de", fontWeight: "600" }}>
                  {" "}
                  products, services{" "}
                </span>{" "}
                recommended by friends across India
              </h2>
            </div>

            <div className=" row ms-1">
              <div className="col-md-3 p-0 banner_autocomplete">
                <div className="banner_pp">
                  <p className="banner_p">Where?</p>
                </div>
                <div style={{ borderRight: "1px solid #eee" }}>
                  <Autocomplete
                    onOpen={() => {
                      if (inputValue) {
                        setOpen(true);
                      }
                    }}
                    inputValue={inputValue}
                    onClose={() => setOpen(false)}
                    options={city}
                    getOptionLabel={(city) =>
                      city &&
                      city.cityArea &&
                      city.cityArea.areaName !== undefined &&
                      city &&
                      city.cityArea &&
                      city.cityArea.areaName !== null
                        ? ` ${
                            city && city.cityArea && city.cityArea.areaName
                          }, ${city.display_name}`
                        : `${city.display_name}`
                    }
                    onChange={handleCityChange}
                    onInputChange={(event, newInputValue, reason) => {
                      setInputValue(newInputValue);

                      // localStorage.setItem("city", newInputValue);
                      handleCity(event.target.value);
                      if (!newInputValue) {
                        setOpen(false);
                      }
                    }}
                    freeSolo
                    autoHighlight
                    sx={{
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "none",
                        },
                      bgcolor: "white",
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="City"
                        // InputProps={{
                        //   startAdornment: (
                        //     <InputAdornment position="start">
                        //       <LocationOnIcon />
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                    )}
                    open={open}
                  />
                </div>
              </div>
              <div className="col-md-7 p-0">
                <div className="banner_pp">
                  <p className="banner_p ">Select Category</p>
                </div>

                <Autocomplete
                  onClose={() => setProOpen(false)}
                  options={productList}
                  defaultValue={null}
                  getOptionLabel={(productList) =>
                    productList &&
                    productList.categories &&
                    productList.categories.type !== undefined
                      ? `${productList.title}, ${
                          productList &&
                          productList.categories &&
                          productList.categories.type
                        }`
                      : `${productList.title}`
                  }
                  onChange={handleproductChange}
                  onInputChange={handleproduct}
                  freeSolo
                  autoHighlight
                  value={valueProduct?.title || null}
                  inputValue={inpPrValue}
                  // sx={{ bgcolor: "white" }}
                  sx={{
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                      },
                    bgcolor: "white",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Products & Services"
                      // onKeyDown={(e) => {
                      //   if (e.code === "enter" && e.target.value) {
                      //     setInputProduct(e.target.value);
                      //   }
                      // }}
                    />
                  )}
                />

                {/* <Button variant="contained" className='home_button' onClick={handleSubmit} >Search</Button> */}
              </div>

              <div className="col-md-2 p-0 btn-col">
                <Button className="home_button" onClick={handleSubmit}>
                  <SearchIcon />
                  Search
                </Button>
              </div>
            </div>

            <div className="row mt-5 w-50  counter ">
              <div className="col-lg-3 single_member_counter ">
                <h4 className="counter">
                  <CountUp duration={1} end={21045} separator=" ," />
                </h4>
                <h5>Products</h5>
              </div>
              <div className="col-lg-3 single_member_counter text-center ">
                <h4 className="counter">
                  <CountUp duration={1} end={600890} separator=" ," />
                </h4>
                <h5> Services</h5>
              </div>
              <div className="col-lg-3 single_member_counter  text-center ">
                <h4 className="counter">
                  <CountUp duration={1} end={180433} separator=" ," />
                </h4>
                <h5>Ratings</h5>
              </div>
              <div className="col-lg-3 single_member_counter text-center  ">
                <h4 className="counter">
                  <CountUp duration={1} end={5666324} separator=" ," />
                </h4>
                <h5>Visits</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ background: "#f9f9f9" }}>
        <RecentReviews cityObject={cityObject} inputvalue={localCity} />
      </div>
      <CatergoryList cityObject={cityObject} inputvalue={localCity} />
      {/*------------------------------------ Modal for City------------------------ */}
      <Modal
        open={show}
        onClose={handleClose}
        // aria-labelledby="parent-modal-title"
        // aria-describedby="parent-modal-description"
        hideBackdrop
      >
        <div className=" col-md-6 citymodal">
          <div className="cityButton_cont">
            <Button className="cityButton" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </div>
          <div className="citylist_cont">
            {cityList.map((item, index) => (
              <p
                onClick={(e) => handlecitySubmit(item)}
                className="cityList_item"
                key={index}
              >
                {item.city_name}
              </p>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Home;
