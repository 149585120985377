import React, { useState } from "react";
import { TextField, Box } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import Modal from "@mui/material/Modal";
import { NotificationManager } from "react-notifications";
import { GoogleLogin } from "react-google-login";

// import { gapi } from 'gapi-script';

// const clientId = '473145374759-eq8cb1187r0lkm771kofdhfra5bnhoc0.apps.googleusercontent.com';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "40vh",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

const Register = (props) => {
  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [show, setShow] = useState(false);
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passError, setPassError] = useState("");
  const [rePassError, setRePassError] = useState("");

  const validate = (name, value) => {
    if (name === "email") {
      let mailFormat =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
      if (mailFormat.test(value)) {
        setEmailError(" ");
      } else {
        setEmailError("EmailAddress or PhoneNumber is not valid");
      }
    } else if (name === "phone") {
      if (value.length > 9) {
        setPhoneError("");
      } else {
        setPhoneError("Enter a 10 digit Phone Number");
      }
    } else if (name === "fullname") {
      if (value.length > 2) {
        setNameError("");
      } else {
        setNameError("Enter a valid name");
      }
    } else if (name === "password") {
      if (value.length > 6) {
        setPassError("");
      } else {
        setPassError("Enter a 6 digit password");
      }
    } else if (name === "repassword") {
      if (password === value) {
        setRePassError("");
      } else {
        setRePassError("Password did not matched");
      }
    }
  };
  const onSuccess = (res) => {
    //setProfile(res.profileObj);
    // console.log(res.profileObj);

    setFullName(res.profileObj.name);
    setEmail(res.profileObj.email);
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };

  const handleClose = () => setShow(false);

  const handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    validate(nam, val);
    if (nam === "email") {
      setEmail(val);
    } else if (nam === "fullname") {
      setFullName(val);
    } else if (nam === "phone") {
      setPhone(val);
    } else if (nam === "password") {
      setPassword(val);
    } else if (nam === "repassword") {
      setRePassword(val);
    } else if (nam === "otp") {
      setOtp(val);
    }
  };

  const handleSubmit = () => {
    let data = {
      name: fullname,
      phone_mail: email,
      // phone: phone,
      pass: password,
      confirmPass: repassword,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "auth/signup", data)
      .then((response) => {
        if (response && response.data && response.data.success === true) {
          setToken(response.data.token);
          setUserId(response.data.data._id);

          setShow(true);
          // props.close()
          NotificationManager.success(response && response.data.message);
        } else {
          NotificationManager.error(response && response.data.message);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // console.log(error.response.data.error)
        NotificationManager.error(error.response.data.error);
      });
  };

  const handleSubmitOtp = () => {
    let data = {
      _id: userId,
      hash: token,
      otp: otp,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "auth/verifyenduser", data)
      .then((response) => {
        // console.log(response.data)
        console.log(response.data);
        setShow(false);
        NotificationManager.success("User Signed up Successfully");
        props.close();
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  };

  return (
    <>
      <div className="text-center overflow-auto ">
        <h4 className="p-2">Lets Create your Account ! </h4>
        <GoogleLogin
          clientId="473145374759-eq8cb1187r0lkm771kofdhfra5bnhoc0.apps.googleusercontent.com"
          buttonText="Sign Up with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
        />
        <div className="text-center mb-3  mt-3">
          <TextField
            label="Full Name"
            sx={{ width: "90%", m: 1 }}
            name="fullname"
            value={fullname}
            onChange={handleChange}
          />
          {nameError !== "" ? <p className="text-danger">{nameError}</p> : null}
          {/* <TextField
            label="Phone"
            sx={{ width: "90%", m: 1 }}
            name="phone"
            type="telephone"
            value={phone}
            onChange={handleChange}
          /> */}
          {phoneError.length !== "" ? (
            <p className="text-danger">{phoneError}</p>
          ) : null}
          <TextField
            label="Email/Phone"
            sx={{ width: "90%", m: 1 }}
            name="email"
            value={email}
            onChange={handleChange}
          />
          {emailError.length !== "" ? (
            <p className="text-danger">{emailError}</p>
          ) : null}
          <TextField
            label="Password"
            sx={{ width: "90%", m: 1 }}
            name="password"
            type="password"
            value={password}
            onChange={handleChange}
          />
          {passError !== "" ? <p className="text-danger">{passError}</p> : null}
          <TextField
            label="Confirm Password"
            sx={{ width: "90%", m: 1 }}
            name="repassword"
            value={repassword}
            type="password"
            onChange={handleChange}
          />
          {rePassError !== "" ? (
            <p className="text-danger">{rePassError}</p>
          ) : null}
        </div>

        <Button
          variant="contained"
          sx={{ width: "90%" }}
          onClick={handleSubmit}
        >
          Register
        </Button>
      </div>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="text-center">
            <h4 className="mt-4">Enter the OTP</h4>
            <TextField
              label="otp"
              sx={{ width: "90%", mt: 4 }}
              name="otp"
              value={otp}
              onChange={handleChange}
            />
            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "90%", mt: 4 }}
                onClick={handleSubmitOtp}
              >
                Verify Otp
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Register;
