import React from 'react'
import { useEffect } from 'react'

const GoogleAds = (props) => {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, [])
    return (
        <ins className='adsbygoogle'
            style={{ display: 'block' }}
            data-ad-client='ca-pub-9866282148671109'
            data-ad-slot={props.slot}
            data-ad-format='auto'
            data-full-width-responsive="true"
        >
        </ins>
    )
}

export default GoogleAds