import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LoginIcon from "@mui/icons-material/Login";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Login from "../Login/login";
import Register from "../Register/register";
import avatar from "../../assets/avatar-raavalo.png";

import revaalo from "../../assets/revaalo2.png";
import "./style.css";

const pages = ["Discussion", "Polls", "Blogs"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  overflowX: "hidden",
};

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [picture, setPicture] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (token) => {
    // setIsLoggedIn(token)
    // window.location.reload()
    setOpen(false);
  };
  const [value, setValue] = useState("one");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("token"));
    if (
      localStorage.getItem("picture") !== undefined &&
      localStorage.getItem("picture") !== null &&
      localStorage.getItem("picture") !== null
    ) {
      setPicture(localStorage.getItem("picture"));
    }
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("mytime");
    localStorage.removeItem("userName");
    localStorage.removeItem("role");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("picture");
    setIsLoggedIn(null);
    navigate("/");
  };

  return (
    <>
      <AppBar position="sticky" sx={{ bgcolor: "white", color: "grey" }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <Link
                to="/"
                className="d-flex justify-content-center align-items-center"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={revaalo}
                  alt="logo"
                  style={{ width: "65px", height: "45px" }}
                />
                <span style={{ fontWeight: 700, fontSize: "30px" }}>
                  Revaalo
                </span>
              </Link>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                color: "black",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon style={{ color: "grey" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
              
                  <MenuItem onClick={()=>{navigate("/blogs");handleCloseNavMenu()}}>
                    <Typography textAlign="center">Blogs</Typography>
                  </MenuItem>
                  <MenuItem onClick={()=>{navigate("/discussion");handleCloseNavMenu()}}>
                    <Typography textAlign="center">Discussion</Typography>
                  </MenuItem>
                  <MenuItem  onClick={()=>{navigate("/contact-us");handleCloseNavMenu()}}>
                    <Typography textAlign="center">Contact Us</Typography>
                  </MenuItem>
      
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none", mx: "auto", flexGrow: 1 },
              }}
            >
              <Link
                to="/"
                className="d-flex justify-content-center align-items-center"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={revaalo}
                  alt="logo"
                  style={{ width: "80px", height: "50px" }}
                />
                <span style={{ fontWeight: 700, fontSize: "30px" }}>
                  Revaalo
                </span>
              </Link>
            </Box>
            <Divider
              flexItem
              sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
            />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                color: "black",
                mt: "6px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => navigate("/discussion")}
                style={{ my: 0, color: "grey", display: "block" }}
              >
                Discussion
              </Button>
              <Button
                onClick={() => navigate("/blogs")}
                style={{ my: 0, color: "grey", display: "block" }}
              >
                Blogs
              </Button>
              {/* <Button
                // onClick={() => navigate("/blogs")}
                style={{ my: 0, color: "grey", display: "block" }}
              >
                Polls
              </Button> */}
              <Button
                onClick={() => navigate("/contact-us")}
                style={{ my: 0, color: "grey", display: "block" }}
              >
                Contact Us
              </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {isLoggedIn !== null ? (
                <>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar
                        alt={localStorage.getItem("userName")}
                        src={
                          picture !== "" &&
                          picture !== null &&
                          picture !== undefined
                            ? picture
                            : avatar
                        }
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <MenuItem onClick={handleCloseUserMenu}>
                      <Link
                        to="/profilepage"
                        style={{ textDecoration: "none", color: "black" }}
                      >
                        <Typography textAlign="center">Profile</Typography>
                      </Link>
                    </MenuItem>

                    <MenuItem onClick={handleCloseUserMenu}>
                      <Typography textAlign="center" onClick={handleLogout}>
                        Logout
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <Button
                  style={{ fontSize: "14px", color: "grey" }}
                  onClick={handleOpen}
                >
                  {" "}
                  <LoginIcon sx={{ marginRight: "8px" }} />
                  <Typography
                    textAlign="center"
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    Login / Register{" "}
                  </Typography>
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Login" value="one" />
                <Tab label="Register" value="two" />
                <Button
                  onClick={handleClose}
                  style={{ marginLeft: "15rem", color: "grey" }}
                >
                  <CloseIcon />
                </Button>
              </TabList>
            </Box>
            <TabPanel value="one">
              <Login close={handleClose} />
            </TabPanel>
            <TabPanel value="two">
              <Register close={handleClose} />
            </TabPanel>
          </TabContext>
        </Box>
        {/* <div className='modal_back'>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Login" value="one" />
                <Tab label="Register" value="two" />
                <Button onClick={handleClose} style={{ marginLeft: "18rem", color: "grey" }}><CloseIcon /></Button>

              </TabList>
            </Box>
            <TabPanel value="one"><Login close={handleClose} /></TabPanel>
            <TabPanel value="two" style={{ height: "10rem ", overflowX: "scroll" }}><Register close={handleClose} /></TabPanel>

          </TabContext>

        </div> */}
      </Modal>
    </>
  );
};
export default ResponsiveAppBar;
