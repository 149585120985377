import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import axios from "axios";
import { Link } from "react-router-dom";
import "./style.css";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
const CategoryList = (props) => {
  const [category, setCategory] = useState([]);
  const [expand, setExpand] = useState(false);
  const { cityObject,inputvalue } = props;
  useEffect(() => {
    let postvalues={
      city_name:cityObject !== null && cityObject && cityObject.city_name !== undefined ? cityObject.city_name:inputvalue
    }
    axios
      .post(process.env.REACT_APP_BACKEND + "category/listing",postvalues)
      .then((res) => {
        setCategory(res.data.data);
      });
  }, [cityObject,inputvalue]);
  const handleChange = () => {
    setExpand((prev) => !prev);

  };

  return (
    <>
      {/* <div>Popular Category</div> */}

      <div className="container p-2">
        <h3 className="text-center mt-3">Popular Categories</h3>
        <Collapse orientation="vertical" in={expand} collapsedSize={510}>
          <div className="row mt-3 p-2 ">
            {category.map((item, index) => {
              return (
                <Link
                  to={
                    item.data.type === "service"
                      ? `${
                          props.cityObject &&
                          props.cityObject.city_name !== undefined &&
                          props.cityObject !== null
                            ? props.cityObject && props.cityObject.city_name
                            : props.inputvalue
                        }/${item.data.slug}`
                      : item.data.slug
                  }
                  state={cityObject}
                  className=" col-md-3 catLink text-center  "
                  key={index}
                >
                  <div className="text-center ">
                    <img
                      src={item && item.data.images}
                      alt=""
                      width="50px"
                      height="50px"
                      className="text-center"
                    />
                  </div>
                  <p className="p-1 rounded mt-2 shadow w-25 m-auto">
                    {item.count}
                  </p>
                  <h6 className="p-3 m-0 pb-0">{item.data.title} </h6>
                  <p className="p-1 m-0">{item.data.description}</p>
                </Link>
              );
            })}
          </div>
        </Collapse>
        <Box container className="btn-hide">
          <Button onClick={handleChange} className="btn">
            Show{" "}
            {expand === true ? (
              <>
                Less
                <KeyboardDoubleArrowUpIcon />
              </>
            ) : (
              <>
                More
                <KeyboardDoubleArrowDownIcon />
              </>
            )}
          </Button>
        </Box>
      </div>
    </>
  );
};

export default CategoryList;
