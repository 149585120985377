import React from "react";
import Img1 from "../../assets/img.png";
import Img2 from "../../assets/img2.png";
import Img3 from "../../assets/img3.png";
import Img4 from "../../assets/img4.png";
import Img5 from "../../assets/arraow1.png";
import Img6 from "../../assets/arrowdown2.png";

const RevaaloBusinessPage = () => {
  return (
    <>
      <div className="container mt-4 mb-4">
        <h2>Revaalo For Business</h2>

        <div className="row mt-5 mb-4 shadow bg-white  p-3">
          <h3 className="text-center mb-5">How It Works</h3>
          <div className="col-md-2 text-center">
            <img src={Img1} alt="" width="60%" />
            <p className="rb_tag ">Get Listed</p>
            <p>
              Get your business listed in revaalo and make your presence felt to
              a larger user base
            </p>
          </div>
          <div className="col-md-1 ">
            <img src={Img5} className="rb-arrowiimg " alt="" />
            <img src={Img6} className="rb_arrowDown" alt="" />
          </div>
          <div className="col-md-2 text-center">
            <img src={Img2} alt="" width="60%" />
            <p className="rb_tag">USER RECOMMENDATIONS</p>
            <p>
              Reviews and recommendations makes all the difference for an end
              user to decide
            </p>
          </div>
          <div className="col-md-1">
            <img src={Img5} className="rb-arrowiimg " alt="" />
            <img src={Img6} className="rb_arrowDown" alt="" />
          </div>
          <div className="col-md-3 text-center">
            <img src={Img3} alt="" width="50%" />
            <p className="rb_tag">NEW USERS VISITS</p>
            <p>
              Users selects the best service or a product available in the
              market
            </p>
          </div>
          <div className="col-md-1 text-center">
            <img src={Img5} className="rb-arrowiimg " alt="" />
            <img src={Img6} className="rb_arrowDown" alt="" />
          </div>
          <div className="col-md-2 text-center">
            <img src={Img4} alt="" width="60%" />
            <p className="rb_tag">BUSINESS GROWTH</p>
            <p>
              With revaalo for business, get insight into how your business
              doing compared to your competitors
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevaaloBusinessPage;
