import React from 'react'
import Home from '../components/home/index.js'
const Homepage = () => {
  return (
    <div>
  <Home />
    </div>
    
  )
}

export default Homepage