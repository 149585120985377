import React, { useState, useEffect } from "react";
import { Rating, Card } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import moment from "moment";
import avatar from "../../assets/avatar-raavalo.png";

var settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
      },
    },
  ],
};

const RecentReviews = (props) => {
  const [recentReviews, setRecentReviews] = useState([]);
  const navigate = useNavigate();
  const { cityObject, inputvalue } = props;

  useEffect(() => {
    let postvalues={
      city_name:cityObject !== null && cityObject && cityObject.city_name !== undefined ? cityObject.city_name:inputvalue
    }
    axios
      .post(process.env.REACT_APP_BACKEND + "review/recentreviews", postvalues)
      .then((response) => {
        setRecentReviews(response.data.data);
      });
  }, [inputvalue, cityObject]);
  const convertDateToDays = (date) => {
    const date1 = new Date();

    const newDate = String(date1).slice(8, 10);

    const date3 = date.slice(8, 10);

    if (date3 === newDate) {
      return "Today";
    } else {
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays < 30) {
        const day = `${diffDays} days ago`;
        return day;
      } else {
        return moment(date).format("DD MMM YYYY");
      }
    }
  };

  const handleNavigate = (slug, id) => {
    const path = `/${slug}`;
    navigate(path, {
      state: { id },
    });
  };
  return (
    <div className="container rr-p  pt-4 pb-5">
      <h3 className=" mb-4  text-center">Recent reviews</h3>
      {/* <Card className="py-3 px-5  shadow-sm">
        <h3 className=" mb-4 ">Recent reviews</h3>

        {recentReviews.map((item, index) => (
          <div
            className="row shadow-sm"
            key={index}
            style={{
              padding: "10px",
              marginTop: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleNavigate(item?.service_ref?.slug, item?._id)}
          >
            <div className="col-md-1 ">
              <img
                src="https://funkylife.in/wp-content/uploads/2022/08/independence-day-images-90.jpg"
                width="100%"
                alt=""
                className="mt-4"
              />
            </div>
            <div className="col-md-10">
              <p className="m-0 p-0 mt-1 " style={{ fontSize: "14px" }}>
                Reviewed by:- {item?.user_ref?.name}
              </p>
              <h5 className="">{item?.service_ref?.title}</h5>

              <div className="d-flex mt-2 justify-content-between">
                <div className="d-flex">
                  <p className="ratingtag">{item?.ratings}</p>
                  <Rating
                    name="read-only"
                    className="ms-2"
                    value={item?.ratings}
                    readOnly
                  />
                </div>
                <p>{moment(item?.createdAt).format("DD-MM-YYYY")}</p>
                <p>{convertDateToDays(item?.createdAt)}</p>
              </div>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </Card> */}

      <Slider {...settings}>
        {recentReviews&&recentReviews.map((item, index) => (
          <div
            onClick={() => handleNavigate(item?.service_ref?.slug, item?._id)}
            key={index}
            className="p-3"
          >
            <Card className="rr_card p-1">
              <div className="d-flex justify-content-center">
                <img
                  src={
                    item?.user_ref?.picture !== null &&  item?.user_ref?.picture !== undefined 
                      ? item?.user_ref?.picture
                      : avatar
                  }
                  width="25%"
                  alt=""
                  className="mt-4 rounded-circle"
                />
              </div>
              <div className="text-center mt-2">
                <p className="m-0 p-0 mt-1 " style={{ fontSize: "14px" }}>
                  {item?.user_ref?.name}
                </p>
                <div className="d-flex justify-content-center align-item-center mt-2">
                  <p className="ratingtag">{item?.ratings}</p>
                  <Rating
                    name="read-only"
                    className="ms-2"
                    value={item?.ratings}
                    readOnly
                  />
                </div>
                <h5 className="rr_title">{item?.service_ref?.title}</h5>

                <div className="text-start p-3 ">
                  <p className="rr_descp">{item.description}</p>

                  <p className=" rr_date">
                    {convertDateToDays(item?.createdAt)}
                  </p>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default RecentReviews;
