import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const Search = (props) => {
  const [city, setCity] = useState([]);
  const [productList, setProductList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [inpPrValue, setInpPrValue] = useState("");
  const [valueProduct, setInputProduct] = useState([]);
  const [open, setOpen] = useState(false);
  const [cityName, setCityName] = useState("");
  const [type, setType] = useState("");
  const [proOpen, setProOpen] = useState(false);
  const [slug, setSlug] = useState("");
  const [alias, setAlias] = useState("");
  const [cityObject, setCityObject] = useState([]);
  const [diffType, setdiffType] = useState("");
  const [show, setShow] = useState(false);
  const [cityList, setcityList] = useState([]);

  let navigate = useNavigate();
  const localCity = localStorage.getItem("city");

  useEffect(() => {
    if (localCity !== null && localCity !== undefined && localCity !== "") {
      setShow(false);
      setInputValue(localCity);
      setCityName(localCity);
    } else {
      setShow(true);
      axios
        .get(process.env.REACT_APP_BACKEND + "city/read")
        .then((res) => {
          setcityList(res.data.data);
        })
        .catch((error) => console.log(error));
    }
  }, []);

  const handleCity = (value) => {
    axios
      .post(process.env.REACT_APP_BACKEND + "search/city", { cityArea: value })
      .then((res) => {
        setCity(res.data);
      });
  };
  const handleproduct = (event, value) => {
    setInpPrValue(value);

    axios
      .post(process.env.REACT_APP_BACKEND + "search/productsandservices", {
        category: value,
      })
      .then((res) => {
        setProductList(res.data.data);
      });
  };
  const handleSubmit = () => {
    if (diffType === "product") {
      let path = `/${slug}`;
      navigate(path);
    } else if (diffType === "service") {
      let path = `/${cityName}/${slug}`;
      navigate(path, { state: cityObject });
    } else if (diffType === "singleService") {
      let path = `/${slug}`;
      navigate(path);
    } else if (diffType === "singleProduct") {
      let path = `/${slug}`;
      navigate(path);
    } else {
      let path = `/${cityName}`;
      navigate(path, { state: { city: cityObject, inp: inpPrValue } });
    }
  };
  const handleproductChange = (event, value) => {
    setInputProduct(value);

    if (value.difftype == "product") {
      setType(value.type);
      setSlug(value.slug);
      setdiffType(value.difftype);
      let path = `/${value.slug}`;
      navigate(path);
    } else if (value.difftype === "service") {
      setSlug(value.slug);
      setdiffType(value.difftype);
      let path = `/${cityName}/${value.slug}`;
      navigate(path, { state: cityObject });
    } else if (value.difftype === "singleService") {
      setType(
        value &&
          value.serviceCategory &&
          value.serviceCategory[0] &&
          value.serviceCategory[0].type
      );
      setSlug(
        value &&
          value.serviceCategory &&
          value.serviceCategory[0] &&
          value.serviceCategory[0].slug
      );
      setAlias(value && value.alias);
      setdiffType(value.difftype);
      let path = `/${value.slug}`;
      navigate(path);
    } else if (value.difftype === "singleProduct") {
      setSlug(value.slug);
      setdiffType(value.difftype);
      let path = `/${value.slug}`;
      navigate(path);
    } else {
    }
  };
  const handleCityChange = (event, value) => {
    setCityName(value.city_name);
    setCityObject(value);
  };
  const handleClose = () => {
    setShow(false);
  };
  const handlecitySubmit = (val) => {
    setInputValue(val.city_name);
    setCityObject(val);
    setCityName(val.city_name);
    localStorage.setItem("city", val.city_name);
    handleClose();
  };

  return (
    // <div className="search_body">
    <div className="row">
      <div className="col-md-3 mb-10-serach  p-0 ">
        <Autocomplete
          fullWidth
          onOpen={() => {
            if (inputValue) {
              setOpen(true);
            }
          }}
          inputValue={inputValue}
          onClose={() => setOpen(false)}
          options={city}
          getOptionLabel={(city) =>
            city &&
            city.cityArea &&
            city.cityArea.areaName !== undefined &&
            city &&
            city.cityArea &&
            city.cityArea.areaName !== null
              ? ` ${city && city.cityArea && city.cityArea.areaName}, ${
                  city.display_name
                }`
              : `${city.display_name}`
          }
          onChange={handleCityChange}
          onInputChange={(event, newInputValue, reason) => {
            setInputValue(newInputValue);
            // localStorage.setItem("city", newInputValue);
            handleCity(event.target.value);
            if (!newInputValue) {
              setOpen(false);
            }
          }}
          freeSolo
          autoHighlight
          sx={{ bgcolor: "white" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="City"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <LocationOnIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
          )}
          open={open}
        />
      </div>
      <div className="col-md-7 mb-10-serach  p-0">
        <Autocomplete
          fullWidth
          onClose={() => setProOpen(false)}
          options={productList}
          getOptionLabel={(productList) =>
            productList &&
            productList.categories &&
            productList.categories.type !== undefined
              ? `${productList.title}, ${
                  productList &&
                  productList.categories &&
                  productList.categories.type
                }`
              : `${productList.title}`
          }
          onChange={handleproductChange}
          onInputChange={handleproduct}
          freeSolo
          autoHighlight
          defaultValue={valueProduct?.title || null}
          inputValue={inpPrValue}
          sx={{ bgcolor: "white" }}
          renderInput={(params) => (
            <TextField {...params} label="Products & Services" />
          )}
        />
      </div>

      <div className="col-md-2 serach-txt-center p-0 ">
        <Button
          className="search_button"
          style={{ marginTop: "0px !important" }}
          onClick={handleSubmit}
        >
          <SearchIcon />
          Search
        </Button>
      </div>
    </div>
    // </div>
  );
};

export default Search;
