import React, { useState, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import { GoogleLogin } from "react-google-login";
import { gapi } from "gapi-script";
import Modal from "@mui/material/Modal";

const clientId =
  "473145374759-eq8cb1187r0lkm771kofdhfra5bnhoc0.apps.googleusercontent.com";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "80vh",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
};

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const[profile,setProfile]=useState([])
  const [show, setShow] = useState(false);
  const [phone, setPhone] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [id, setId] = useState("");
  const [emailerror, setEmailError] = useState("");
  const [passerror, setPassError] = useState("");

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };

    gapi.load("client:auth2", initClient);
  }, []);

  const validate = (value, name) => {
    if (name === "email") {
      let mailFormat =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
      if (mailFormat.test(value)) {
        setEmailError(" ");
      } else {
        setEmailError("EmailAddress or PhoneNumber is not valid");
      }
    }
    if (name === "password") {
      if (value.length <= 6) {
        setPassError("Please enter a 6 digit password");
      } else setPassError("");
    }
  };
  const onSuccess = (res) => {
    axios
      .post(process.env.REACT_APP_BACKEND + "auth/googlelogin", res)
      .then((res) => {
        if (res && res.data && res.data.success === true) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("email", res.data.data.mail);
          localStorage.setItem("id", res.data.data._id);
          localStorage.setItem("picture", res.data.picture);
          window.location.reload();
          props.close();

          NotificationManager.success(res.data.message);
        }
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.error);
      });
  };
  const onFailure = (err) => {
    console.log("failed:", err);
  };
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if (nam === "email") {
      validate(val, nam);
      setEmail(val);
    } else if (nam === "password") {
      validate(val, nam);
      setPassword(val);
    } else if (nam === "phone") {
      setPhone(val);
    } else if (nam === "otp") {
      setOtp(val);
    } else if (nam === "newpassword") {
      setNewPassword(val);
    } else if (nam === "confirmpassword") {
      setConfirmPassword(val);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      username: email,
      pass: password,
    };
    if (email !== "" && password !== "") {
      axios
        .post(process.env.REACT_APP_BACKEND + "auth/login", data, {
          headers: { "content-type": "application/json" },
        })
        .then((response) => {
          // console.log(response.data.message )
          if (response && response.data && response.data.success === true) {
            NotificationManager.success(response.data.message);
            localStorage.setItem("userName", response.data.data.name);
            localStorage.setItem("role", response.data.role);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("email", response.data.data.mail);
            localStorage.setItem("id", response.data.data._id);
            window.location.reload();

            props.close(response.data.token);
          } else {
            NotificationManager.warning(response && response.data.message);
          }
        })
        .catch((error) => {
          //console.error('There was an error!', error);
          //console.log(error.response.data.message)
          NotificationManager.error(error.response.data.message);
          props.close();
        });
    }
  };
  const handleSendOtp = () => {
    const data = {
      phone: phone,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "auth/forgotpasswordgenerate", data)
      .then((response) => {
        //console.log(response.data)
        if (response.data.success === true) {
          NotificationManager.success(response.data.message);
          handleClose();
          setOtpToken(response.data.token);
          setId(response.data._id);

          setShowOtp(true);
        } else {
          NotificationManager.error(response.data.message);
        }
      });
  };
  const handleVerifyOtp = () => {
    const data = {
      _id: id,
      hash: otpToken,
      otp: otp,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "auth/forgotpasswordverify", data)
      .then((response) => {
        //.log(response.data)
        if (response.data.success === true) {
          NotificationManager.success(response.data.message);
          handleClose();
          props.close();
        } else {
          NotificationManager.error(response.data.message);
          setOtp("");
          setNewPassword("");
          setConfirmPassword("");
        }
      })
      .catch((error) => {
        NotificationManager.success(error.response.data.message);
      });
  };
  return (
    <>
      <div className="text-center p-3">
        <h4>We're glad to see you again!</h4>
        {/* <p>Don't have an account? </p> */}
      </div>
      <div className="text-center ">
        <TextField
          label="Phone/Email"
          sx={{ width: "90%", m: 1 }}
          name="email"
          value={email}
          onChange={handleChange}
        />
        <p className="text-danger">{emailerror}</p>
        <TextField
          fullWidth
          label="Password"
          name="password"
          type="password"
          sx={{ width: "90%", m: 1 }}
          value={password}
          onChange={handleChange}
        />
        <p className="text-danger">{passerror}</p>
        <p
          className="text-right"
          style={{ cursor: "pointer" }}
          onClick={handleShow}
        >
          Forget Password?
        </p>
        <Button
          variant="contained"
          sx={{ width: "90%" }}
          onClick={handleSubmit}
        >
          Login
        </Button>
        <Divider className="mt-5">or</Divider>
      </div>

      <div className="d-flex justify-content-around mt-1">
        {/* <Button variant="outlined" sx={{width:'45%', m:1, textTransform: 'lowercase',fontWeight: 'light'}}> 
    <FacebookOutlinedIcon /> Log In via Facebook</Button> */}
        <GoogleLogin
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          className="mt-2"
        />
      </div>
      {/* -------------------SendOtp------------------- */}
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: "40vh" }}>
          <div className="text-center">
            <h4 className="mt-4">Enter Mobile Number</h4>
            <TextField
              label="Enter Mobile Number"
              sx={{ width: "90%", mt: 4 }}
              name="phone"
              value={phone}
              onChange={handleChange}
            />
            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "90%", mt: 4 }}
                onClick={handleSendOtp}
              >
                Send Otp
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      {/* --------------verify passWord and otp--------------------- */}
      <Modal
        open={showOtp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ height: "65vh" }}>
          <div className="text-center">
            <h4 className="mt-4">Enter Mobile Number</h4>
            <TextField
              label="Enter Otp"
              sx={{ width: "90%", mt: 4 }}
              name="otp"
              value={otp}
              onChange={handleChange}
            />
            <TextField
              label="New Password"
              type="password"
              sx={{ width: "90%", mt: 4 }}
              name="newpassword"
              value={newPassword}
              onChange={handleChange}
            />
            <TextField
              label="Confirm Password"
              type="password"
              sx={{ width: "90%", mt: 4 }}
              name="confirmpassword"
              value={confirmPassword}
              onChange={handleChange}
            />
            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "90%", mt: 4 }}
                onClick={handleVerifyOtp}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Login;
