import React from "react";
// import Search from "../Search/Search";

const Terms = () => {
  return (
    <>
      {/* <Search /> */}
      <div className="terms_main">
        <h1 className="terms_f1">Revaalo Terms of Use</h1>
        <p className="terms_f2">
          The domain name www.revaalo.com (hereinafter referred to as "Website")
          is owned by Revaalo Techology Private Limited (hereinafter referred to
          as "revaalo").
        </p>
        <p>
          Your use of the Website and services and tools are governed by the
          following terms and conditions ("Terms of Use") as applicable to the
          Website including the applicable policies which are incorporated
          herein by way of reference. If You transact on the Website, You shall
          be subject to the policies that are applicable to the Website for such
          transaction. By mere use of the Website, You shall be contracting with
          by Revaalo Techology Private Limited and these terms and conditions
          including the policies constitute Your binding obligations, with
          Revaalo.
        </p>
        <p>
          For the purpose of these Terms of Use, wherever the context so
          requires "You" or "User" shall mean any natural or legal person who
          has agreed to become a user on the Website by providing Registration
          Data while registering on the Website as Registered User using the
          computer systems. Revaalo allows the User to surf the Website without
          registering on the Website. The term "We", "Us", "Our" shall mean
          Revaalo Techology Private Limited
        </p>
        <p>
          For the purpose of these Terms of Use, wherever the context so
          requires "You" or "User" shall mean any natural or legal person who
          has agreed to become a user on the Website by providing Registration
          Data while registering on the Website as Registered User using the
          computer systems. Revaalo allows the User to surf the Website without
          registering on the Website. The term "We", "Us", "Our" shall mean
          Revaalo Techology Private Limited
        </p>
        <h2 className="terms_h2">Content</h2>
        <p>
          "Content" will include (but not limited to) any of the following viz.
          text, images, photos, audio, video, location data, nearby places, and
          all other forms of information or data. "Your content" or "User
          Content" means content that you upload, share or transmit to, through
          or in connection with the website and mobile app, such as likes,
          ratings, reviews, images, photos, messages, profile information, and
          any other materials that you publicly display or displayed in your
          account profile. "Revaalo content" means content that Revaalo creates
          and make available in connection with the website including, but not
          limited to, visual interfaces, interactive features, graphics, design,
          compilation, computer code, products, software, aggregate ratings,
          reports and other usage-related data in connection with activities
          associated with your account and all other elements and components of
          the Services excluding Your Content and third party content. "Third
          Party Content" means content that comes from parties other than
          Revaalo or its users and available on the websit
        </p>
        <h2 className="terms_h2">Platform for Transaction and Communication</h2>
        <p>
          When You use the Website or send emails or other data, information or
          communication to us, You agree and understand that You are
          communicating with Us through electronic records and You consent to
          receive communications via electronic records from Us periodically and
          as and when required. We may communicate with you by email or by such
          other mode of communication, electronic or otherwise.
        </p>
        <p>
          The Website is a platform that Users utilize to meet and interact with
          one another for their transactions. Revaalo is not and cannot be a
          party to or control in any manner any transaction between the
          Website's Users.
        </p>
        <p>
          You release and indemnify Revaalo and/or any of its officers and
          representatives from any cost, damage, liability or other consequence
          of any of the actions of the Users of the Website and specifically
          waive any claims that you may have in this behalf under any applicable
          law. Notwithstanding its reasonable efforts in that behalf, Revaalo
          cannot take responsibility or control the information provided by
          other Users which is made available on the Website. You may find other
          User's information to be offensive, harmful, inconsistent, inaccurate,
          or deceptive. Please use caution and practice safe trading when using
          the Website.
        </p>
        <p>
          Please note that there could be risks in dealing with underage persons
          or people acting under false pretense.
        </p>
        <p>
          Private communication, including email correspondence, is not
          regulated by Revaalo. Revaalo encourages its Users to be professional,
          courteous and respectful when communicating by email.
        </p>
        <p>
          However, Revaalo will investigate and can take action on certain types
          of unwanted communications that violate Revaalo policies.
        </p>
        <h2>Use of the Website</h2>
        <p>
          You agree, undertake and confirm that Your use of Website shall be
          strictly governed by the following binding principles:
        </p>
        <p>
          1. You agree, undertake and confirm that Your use of Website shall be
          strictly governed by the following binding principles:
        </p>
        <p>
          (a) belongs to another person and to which You does not have any right
          to;
        </p>
        <p>
          (b) is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, pedophilic, libelous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever; or unlawfully threatening or unlawfully harassing
          including but not limited to "indecent representation of women" within
          the meaning of the Indecent Representation of Women (Prohibition) Act,
          1986;
        </p>
        <p>
          (b) is grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, pedophilic, libelous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever; or unlawfully threatening or unlawfully harassing
          including but not limited to "indecent representation of women" within
          the meaning of the Indecent Representation of Women (Prohibition) Act,
          1986;
        </p>
        <p>(c) is misleading in any way;</p>
        <p>
          (d) is patently offensive to the online community, such as sexually
          explicit content, or content that promotes obscenity, pedophilia,
          racism, bigotry, hatred or physical harm of any kind against any group
          or individual;
        </p>
        <p>(e) harasses or advocates harassment of another person;</p>
        <p>
          (f) involves the transmission of "junk mail", "chain letters", or
          unsolicited mass mailing or "spamming";
        </p>
        <p>
          (g) promotes illegal activities or conduct that is abusive,
          threatening, obscene, defamatory or libelous;
        </p>
        <p>
          (h) infringes upon or violates any third party's rights [including,
          but not limited to, intellectual property rights, rights of privacy
          (including without limitation unauthorized disclosure of a person's
          name, email address, physical address or phone number) or rights of
          publicity;
        </p>
        <p>
          (i) promotes an illegal or unauthorized copy of another person's
          copyrighted work (see "Copyright complaint" below for instructions on
          how to lodge a complaint about uploaded copyrighted material), such as
          providing pirated computer programs or links to them, providing
          information to circumvent manufacture-installed copy-protect devices,
          or providing pirated music or links to pirated music files;
        </p>
        <p>
          (j) contains restricted or password-only access pages, or hidden pages
          or images (those not linked to or from another accessible page);
        </p>
        <p>
          (k) provides material that exploits people in a sexual, violent or
          otherwise inappropriate manner or solicits personal information from
          anyone;
        </p>
        <p>
          (l) provides instructional information about illegal activities such
          as making or buying illegal weapons, violating someone's privacy, or
          providing or creating computer viruses;
        </p>
        <p>
          (l) provides instructional information about illegal activities such
          as making or buying illegal weapons, violating someone's privacy, or
          providing or creating computer viruses;
        </p>
        <p>
          (n) tries to gain unauthorized access or exceeds the scope of
          authorized access to the Website or to profiles, blogs, communities,
          account information, bulletins, friend request, or other areas of the
          Website or solicits passwords or personal identifying information for
          commercial or unlawful purposes from other users;
        </p>
        <p>
          (o) engages in commercial activities and/or sales without Our prior
          written consent such as contests, sweepstakes, barter, advertising and
          pyramid schemes, or the buying or selling of "virtual" products
          related to the Website. Throughout this Terms of Use, Revaalo's prior
          written consent means a communication coming from Revaalo's Legal
          Department, specifically in response to Your request, and specifically
          addressing the activity or conduct for which You seek authorization;
        </p>
        <p>
          (p) solicits gambling or engages in any gambling activity which We, in
          Our sole discretion, believes is or could be construed as being
          illegal;
        </p>
        <p>
          (q) interferes with another USER's use and enjoyment of the Website or
          any other individual's User and enjoyment of similar services;
        </p>
        <p>
          (r) refers to any website or URL that, in Our sole discretion,
          contains material that is inappropriate for the Website or any other
          website, contains content that would be prohibited or violates the
          letter or spirit of these Terms of Use.
        </p>
        <p>(s) harm minors in any way;</p>
        <p>
          (t) infringes any patent, trademark, copyright or other proprietary
          rights or third party's trade secrets or rights of publicity or
          privacy or shall not be fraudulent or involve the sale of counterfeit
          or stolen products;
        </p>
        <p>(u) violates any law for the time being in force;</p>
        <p>
          (v) deceives or misleads the addressee/ users about the origin of such
          messages or communicates any information which is grossly offensive or
          menacing in nature;
        </p>
        <p>(w) impersonate another person;</p>
        <p>
          (x) contains software viruses or any other computer code, files or
          programs designed to interrupt, destroy or limit the functionality of
          any computer resource; or contains any trojan horses, worms, time
          bombs, cancelbots, easter eggs or other computer programming routines
          that may damage, detrimentally interfere with, diminish value of,
          surreptitiously intercept or expropriate any system, data or personal
          information;
        </p>
        <p>
          (y) threatens the unity, integrity, defense, security or sovereignty
          of India, friendly relations with foreign states, or public order or
          causes incitement to thecommission of any cognizable offence or
          prevents investigation of any offence or is insulting any other
          nation.
        </p>
        <p>(z) shall not be false, inaccurate or misleading</p>
        <p>
          (aa) shall not, directly or indirectly, offer, attempt to offer, trade
          or attempt to trade in any item, the dealing of which is prohibited or
          restricted in any manner under the provisions of any applicable law,
          rule, regulation or guideline for the time being in force.
        </p>
        <p>
          (ab) shall not create liability for Us or cause Us to lose (in whole
          or in part) the services of Our internet service provider ("ISPs") or
          other suppliers;
        </p>
        <p>
          2. You shall not use any "deep-link", "page-scrape", "robot", "spider"
          or other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to access, acquire, copy or
          monitor any portion of the Website or any Content, or in any way
          reproduce or circumvent the navigational structure or presentation of
          the Website or any Content, to obtain or attempt to obtain any
          materials, documents or information through any means not purposely
          made available through the Website. We reserve Our right to bar any
          such activity.
        </p>
        <p>
          3. You shall not attempt to gain unauthorized access to any portion or
          feature of the Website, or any other systems or networks connected to
          the Website or toany server, computer, network, or to any of the
          services offered on or through the Website, by hacking, password
          "mining" or any other illegitimate means.
        </p>
        <p>
          4. You shall not probe, scan or test the vulnerability of the Website
          or any network connected to the Website nor breach the security or
          authentication measures on the Website or any network connected to the
          Website. You may not reverse look-up, trace or seek to trace any
          information on any other User of or visitor to Website, or any other
          customer, including any account on the Website not owned by You, to
          its source, or exploit the Website or any service or information made
          available or offered by or through the Website, in any way where the
          purpose is to reveal any information, including but not limited to
          personal identification or information, other than Your own
          information, as provided for by the Website.
        </p>
        <p>
          5. You agree not to use any device, software or routine to interfere
          or attempt to interfere with the proper working of the Website or any
          transaction being conducted on the Website, or with any other person's
          use of the Website.
        </p>
        <p>
          6. You may not forge headers or otherwise manipulate identifiers in
          order to disguise the origin of any message or transmittal You send to
          Us on or through the Website or any service offered on or through the
          Website. You may not pretend that You are, or that You represent,
          someone else, or impersonate any other individual or entity.
        </p>
        <p>
          7. You may not use the Website or any content for any purpose that is
          unlawful or prohibited by these Terms of Use, or to solicit the
          performance of any illegal activity or other activity which infringes
          the rights of Revaalo and / or others.
        </p>
        <p>
          8. You shall at all times ensure full compliance with the applicable
          provisions of the Information Technology Act, 2000 and rules
          thereunder as applicable and as amended from time to time and also all
          applicable Domestic laws, rules and regulations (including the
          provisions of any applicable Exchange Control Laws or Regulations in
          Force) and International Laws, Foreign Exchange Laws, Statutes,
          Ordinances and Regulations (including, but not limited to Sales
          Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom Duty,
          Local Levies) regarding Your use of Our service and Your listing,
          purchase, solicitation of offers to purchase, and sale of products or
          services or display of events. You shall not engage in any transaction
          in an item or service, which is prohibited by the provisions of any
          applicable law including exchange control laws or regulations for the
          time being in force.
        </p>
        <p>
          9. Solely to enable Us to use the information You supply Us with, so
          that we are not violating any rights You might have in Your
          Information, You agree to grant Us a non-exclusive, worldwide,
          perpetual, irrevocable, royalty-free, sub-licensable (through multiple
          tiers) right to exercise the copyright, publicity, database rights or
          any other rights You have in Your Information, in any media now known
          or not currently known, with respect to Your Information. We will only
          use Yourinformation in accordance with the Terms of Use and Privacy
          Policy applicable to use of the Website.
        </p>
        <p>
          10. From time to time, You shall be responsible for providing
          information relating to the products or services proposed to be
          sold/provided by You. In this connection, You undertake that all such
          information shall be accurate in all respects. You shall not
          exaggerate or over emphasize the attributes of such products or
          services so as to mislead other Users in any manner.
        </p>
        <p>
          11. You shall not engage in advertising to, or solicitation of, other
          Users of the Website to buy or sell any products or services,
          including, but not limited to, products or services related to that
          being displayed on the Website or related to us. You may not transmit
          any chain letters or unsolicited commercial or junk email to other
          Users via the Website. It shall be a violation of these Terms of Use
          to use any information obtained from the Website in order to harass,
          abuse, or harm another person, or in order to contact, advertise to,
          solicit, or sell to another person other than Us without Our prior
          explicit consent. In order to protect Our Users from such advertising
          or solicitation, We reserve the right to restrict the number of
          messages or emails which a user may send to other Users in any 24-hour
          period which We deems appropriate in its sole discretion. You
          understand that We have the right at all times to disclose any
          information (including the identity of the persons providing
          information or materials on the Website) as necessary to satisfy any
          law, regulation or valid governmental request. This may include,
          without limitation, disclosure of the information in connection with
          investigation of alleged illegal activity or solicitation of illegal
          activity or in response to a lawful court order or subpoena. In
          addition, We can (and You hereby expressly authorize Us to) disclose
          any information about You to law enforcement or other government
          officials, as we, in Our sole discretion, believe necessary or
          appropriate in connection with the investigation and/or resolution of
          possible crimes, especially those that may involve personal injury.
        </p>
        <p>
          We reserve the right, but has no obligation, to monitor the materials
          posted on the Website. Revaalo shall have the right to remove or edit
          any content that in its sole discretion violates, or is alleged to
          violate, any applicable law or either the spirit or letter of these
          Terms of Use. Notwithstanding this right, YOU REMAIN SOLELY
          RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE
          AND IN YOUR PRIVATE MESSAGES. Please be advised that such Content
          posted does not necessarily reflect Revaalo views. In no event shall
          Revaalo assume or have any responsibility or liability for any Content
          posted or for any claims, damages or losses resulting from use of
          Content and/or appearance of Content on the Website. You hereby
          represent and warrant that You have all necessary rights in and to all
          Content which You provide and all information it contains and that
          such Content shall not infringe any proprietary or other rights of
          third parties or contain any libelous, tortious, or otherwise unlawful
          information.
        </p>
        <p>
          12. Your correspondence or business dealings with, or participation in
          promotions of, advertisers found on or through the Website, including
          payment and delivery of related products or services, and any other
          terms, conditions, warranties or representations associated with such
          dealings, are solely between You and such advertiser. We shall not be
          responsible or liable for any loss or damage of any sort incurred as
          the result of any such dealings or as the result of the presence of
          such advertisers on the Website.
        </p>
        <p>
          13. It is possible that other users (including unauthorized users or
          "hackers") may post or transmit offensive or obscene materials on the
          Website and that You may be involuntarily exposed to such offensive
          and obscene materials. It also is possible for others to obtain
          personal information about You due to your use of the Website, and
          that the recipient may use such information to harass or injure You.
          We does not approve of such unauthorized uses, but by using the
          Website You acknowledge and agree that We are not responsible for the
          use of any personal information that You publicly disclose or share
          with others on the Website. Please carefully select the type of
          information that You publicly disclose or share with others on the
          Website.
        </p>
        <p>
          13. It is possible that other users (including unauthorized users or
          "hackers") may post or transmit offensive or obscene materials on the
          Website and that You may be involuntarily exposed to such offensive
          and obscene materials. It also is possible for others to obtain
          personal information about You due to your use of the Website, and
          that the recipient may use such information to harass or injure You.
          We does not approve of such unauthorized uses, but by using the
          Website You acknowledge and agree that We are not responsible for the
          use of any personal information that You publicly disclose or share
          with others on the Website. Please carefully select the type of
          information that You publicly disclose or share with others on the
          Website.
        </p>
        <p>
          14. Revaalo shall have all the rights to take necessary action and
          claim damages that may occur due to your involvement/participation in
          any way on your ownor through group/s of people, intentionally or
          unintentionally in DoS/DDoS (Distributed Denial of Services)
        </p>
        <p>
          15. Revaalo reserves the right to charge subscription and / or
          membership fees from a user, by giving reasonable prior notice, in
          respect of any product, service or any other aspect of this site
          anytime in future.
        </p>
        <p>
          16. You must create an account in order to use some of the features
          offered by the Services, including without limitation to ‘claim your
          listing’ on the Services. Use of any personal information you provide
          to us during the account creation process is governed by our Privacy
          Policy. You must keep your password confidential and you are solely
          responsible for maintaining the confidentiality and security of your
          account, all changes and updates submitted through your account, and
          all activities that occur in connection with your account.
        </p>
        <p>
          You may also be able to register to use the Services by logging into
          your account with your credentials from certain third party social
          networking sites (e.g., Facebook, Google). You confirm that you are
          the owner of any such social media account and that you are entitled
          to disclose your social media login information to us. You authorize
          us to collect your authentication information, and other information
          that may be available on or through your social media account
          consistent with your applicable settings and instructions.
        </p>
        <p>
          In creating an account and/or claiming your business’ listing, you
          represent to us that all information provided to us in such process is
          true, accurate and correct, and that you will update your information
          as and when necessary in order to keep it accurate. If you are
          creating an account or claiming a business listing, then you represent
          to us that you are the owner or authorized agent of such business. You
          may not impersonate someone else, create or use an account for anyone
          other than yourself, provide an email address other than your own,
          create multiple accounts or business listings except as otherwise
          authorized by us, or provide or use false information to obtain access
          to a business’ listing on the Services that you are not legally
          entitled to claim. You acknowledge that any false claiming of a
          business listing may cause Revaalo or third parties to incur
          substantial economic damages and losses for which you may be held
          liable and accountable.
        </p>
        <p>
          You are also responsible for all activities that occur in your
          account. You agree to notify us immediately of any unauthorized use of
          your account in order to enable us to take necessary corrective
          action. You also agree that you will not allow any third party to use
          your account for the purpose of transacting in your name on Revaalo’s
          website.
        </p>
        <p>
          By creating an account, you agree to receive certain communications in
          connection with the Site or Services. For example, you might receive
          comments or friend requests from other Users. You can opt-out or
          manage your preferences regarding non-essential communications through
          account settings.
        </p>
        <h2>Contents Posted on Site</h2>
        <p>
          All text, graphics, user interfaces, visual interfaces, photographs,
          trademarks, logos, sounds, music and artwork (collectively,
          "Content"), is a third party user or end user generated content and
          Revaalo has no control over such third party user generated content as
          Revaalo is merely an intermediary for the purposes of this Terms of
          Use. The contents of this website are for informational purposes only.
        </p>
        <p>
          Except as expressly provided in these Terms of Use, no part of the
          Website and no Content may be copied, reproduced, republished,
          uploaded, posted, publicly displayed, encoded, translated, transmitted
          or distributed in any way (including "mirroring") to any other
          computer, server, Website or other medium for publication or
          distribution or for any commercial enterprise, without Revaalo's
          express prior written consent.
        </p>
        <p>
          You may use information on the products and services purposely made
          available on the Website for downloading, provided that You (1) do not
          remove any proprietary notice language in all copies of such
          documents, (2) use such information only for your personal,
          non-commercial informational purpose and do not copy or post such
          information on any networked computer or broadcast it in any media,
          (3) make no modifications to any such information, and (4) do not make
          any additional representations or warranties relating to such
          documents.
        </p>
        <p>
          You shall be responsible for any notes, messages, emails, billboard
          postings, photos, drawings, profiles, opinions, ideas, images, videos,
          audio files or other materials or information posted or transmitted to
          the Website (collectively, "Content"). Such Content will become Our
          property and You grant Us the worldwide, perpetual and transferable
          rights in such Content. We shall be entitled to, consistent with Our
          Privacy Policy as adopted in accordance with applicable law, use the
          Content or any of its elements for any type of use forever, including
          but not limited to promotional and advertising purposes and in any
          media whether now known or hereafter devised, including the creation
          of derivative works that may include the Content You provide. You
          agree that any Content You post may be used by us, consistent with Our
          Privacy Policy and Rules of Conduct on Site as mentioned herein, and
          You are not entitled to any payment or other compensation for such
          use.
        </p>
        <p>
          Revaalo is constantly evolving in order to provide the best possible
          experience and information to its users. You acknowledge and agree
          that the form and nature of the Services which Revaalo provides may
          require effecting certain changes in it, therefore Revaalo reserves
          the right to suspend / cancel, or discontinue any or all products or
          services at any time without notice, make modifications and
          alterations in any or all of its contents, products and services
          contained on the site without any prior notice.
        </p>
        <p>
          We reserve the right, at any time and without prior notice, to remove,
          block, or disable access to any content that we, for any reason or no
          reason, consider to be objectionable, in violation of the Terms or
          otherwise harmful to the Services or our users in our sole discretion.
          Subject to the requirements of applicable law, we are not obligated to
          return any of Your Content to you under any circumstances. Any review,
          which is derogatory, defamatory or hateful and without any substantial
          evidence (e.g. photograph or video) may be taken down at our sole
          discretion.
        </p>
        <h2>User Reviews</h2>
        <p>
          User Review does not reflect the opinion of Revaalo. Revaalo receives
          multiple reviews by users which reflect the opinions of the Users. It
          is pertinent to state that each and every review posted on Revaalo is
          the personal opinion of the User / Reviewer only. Revaalo is a neutral
          platform which solely provides a means of communication between Users/
          Reviewers including users or restaurant owners/ representatives with
          access to restaurant business page. The advertisements on Revaalo are
          published on an independent basis and there is no relation between the
          reviews of advertisers those who have advertised on the Revaalo’s
          website. We are a neutral platform and we don’t arbitrate disputes,
          however incase if someone writes a review that isn’t true about the
          restaurant, best option for the restaurant representative would be to
          contact the reviewer or post a public response in order to clear up
          any misunderstandings. If the restaurant believes that any particular
          user’s review violates Revaalo’s Policy, he can flag the review to our
          attention. Revaalo may remove the review in its sole discretion if
          review is in violation of the Terms, or content guidelines and
          policies or otherwise harmful to the services.
        </p>
        <h2>Advertising</h2>
        <p>
          Some of the Services are supported by advertising revenue and may
          display advertisements and promotions. These advertisements may be
          targeted to the content of information stored on the Services, queries
          made through the Services or other information. The manner, mode and
          extent of advertising by Revaalo on the Services are subject to change
          without specific notice to you. In consideration for Revaalo granting
          you access to and use of the Services, you agree that Revaalo may
          place such advertising on the Services.
        </p>
        <p>
          Part of the site may contain advertising information or promotional
          material or other material submitted to Revaalo by third parties.
          Responsibility for ensuring that material submitted for inclusion on
          the Site complies with applicable International and National law is
          exclusively on the party providing the information/material. Your
          correspondence or business dealings with, or participation in
          promotions of, advertisers other than Revaalo found on or through the
          Website, including payment and delivery of related goods or services,
          and any other terms, conditions, warranties or representations
          associated with such dealings, shall be solely between you and such
          advertiser. Revaalo will not be responsible or liable for any error or
          omission, inaccuracy in advertising material or any loss or damage of
          any sort incurred as a result of any such dealings or as a result of
          the presence of such other advertiser(s) on the Website.
        </p>
        <h2>Privacy</h2>
        <p>
          We view protection of Your privacy as a very important principle. We
          understand clearly that You and Your Personal Information is one of
          Our most important assets. We store and process Your Information
          including any sensitive financial information collected (as defined
          under the Information Technology Act, 2000), if any, on computers that
          may be protected by physical as well as reasonable technological
          security measures and procedures in accordance with Information
          Technology Act 2000 and Rules there under. Our current Privacy Policy
          is available at /s/privacy policy. If You object to Your Information
          being transferred or used in this way please do not use Website.
        </p>
        <p>
          We and our affiliates will share / sell / transfer / license / covey
          some or all of your personal information with another business entity
          should we (or our assets) plan to merge with or are acquired by that
          business entity, or re-organization, amalgamation, restructuring of
          business or for any other reason whatsoever. Should such a transaction
          or situation occur, the other business entity or the new combined
          entity will be required to follow the privacy policy with respect to
          your personal information. Once you provide your information to us,
          you provide such information to us and our affiliate and we and our
          affiliate may use such information to provide you various services
          with respect to your transaction whether such transaction are
          conducted on www.revaalo.com or with third party merchant's or third
          party merchant's website.
        </p>
        <h2>Disclaimer of Warranties and Liability</h2>
        <p>
          This Website, all the materials and products (including but not
          limited to software) and services, included on or otherwise made
          available to You through this site are provided on "as is" and "as
          available" basis without any representation or warranties, express or
          implied except otherwise specified in writing. Without prejudice to
          the forgoing paragraph, Revaalo does not warrant that:
        </p>
        <p>
          • This Website will be constantly available, or available at all; or
        </p>
        <p>
          • The information on this Website is complete, true, accurate or
          non-misleading.
        </p>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          THE REVAALO PARTIES BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
          INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER
          RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT,
          (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
          RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES INCLUDING MOBILE
          APP, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM OUR SERVERS, (V) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR
          THROUGH THE SERVICES BY ANY THIRD PARTY, (VI) ANY LOSS OF YOUR DATA OR
          CONTENT FROM THE SERVICES (VII) ANY ERRORS OR OMISSIONS IN ANY CONTENT
          OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE
          OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
          THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER
          LEGAL THEORY, AND WHETHER OR NOT THE REVAALO PARTIES ARE ADVISED OF
          THE POSSIBILITY OF SUCH DAMAGES, AND/OR (VIII) THE DISCLOSURE OF
          INFORMATION PURSUANT TO THESE TERMS OR OUR PRIVACY POLICY, (IX) YOUR
          FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND
          CONFIDENTIAL, (X) LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU,
          INCLUDING BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF RELIANCE
          PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR EXISTENCE OF ANY
          ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN
          YOU AND ANY ADVERTISER OR SPONSOR WHOSE ADVERTISING APPEARS ON THE
          SERVICES. (XI) ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL
          OR EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND
          UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED
          TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY
          LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED,
          COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR OTHER
          INTANGIBLE LOSS
        </p>
        <h2>Indemnity</h2>
        <p>
          You shall indemnify and hold harmless Revaalo, its owner, licensee,
          affiliates, subsidiaries, group companies (as applicable) and their
          respective officers, directors, agents, and employees, from any claim
          or demand, or actions including reasonable attorneys' fees, made by
          any third party or penalty imposed due to or arising out of Your
          breach of this Terms of Use, privacy Policy and other Policies, or
          Your violation of any law, rules or regulations or the rights
          (including infringement of intellectual property rights) of a third
          party.
        </p>
        <h2>Applicable Law</h2>
        <p>
          Terms of Use shall be governed by and interpreted and construed in
          accordance with the laws of India. The place of jurisdiction shall be
          exclusively in Bangalore.
        </p>
        <h2>Trademark, Copyright and Restriction</h2>
        <p>
          This site is controlled and operated by Revaalo and products are sold
          by respective Sellers. All material on this site, including images,
          illustrations, audio clips, and video clips, are protected by
          copyrights, trademarks, and other intellectual property rights.
          Material on Website is solely for Your personal, non-commercial use.
          You must not copy, reproduce, republish, upload, post, transmit or
          distribute such material in any way, including by email or other
          electronic means and whether directly or indirectly and You must not
          assist any other person to do so. Without the prior written consent of
          the owner, modification of the materials, use of the materials on any
          other website or networked computer environment or use of the
          materials for any purpose other than personal, non-commercial use is a
          violation of the copyrights, trademarks and other proprietary rights,
          and is prohibited. Any use for which You receive any remuneration,
          whether in money or otherwise, is a commercial use for the purposes of
          this clause.
        </p>
        <h2>Trademark complaint</h2>
        <p>
          Revaalo respects the intellectual property of others. In case You feel
          that Your Trademark has been infringed, You can write to Revaalo at
          info@revaalo.in
        </p>
        <h2>Product Description</h2>
        <p>
          Revaalo we do not warrant that Product description or other content of
          this Website is accurate, complete, reliable, current, or error-free
          and assumes no liability in this regard.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          IN NO EVENT SHALL REVAALO BE LIABLE FOR ANY SPECIAL, INCIDENTAL,
          INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE
          TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE
          POSSIBILITY OF SUCH DAMAGES.
        </p>
        <h2>Contact Us</h2>
        <p>
          Please contact us for any questions or comments (including all
          inquiries unrelated to copyright infringement) regarding this Website.
        </p>
        <h2>Reporting Listing Violations</h2>
        <p>
          Revaalo has put in place a process that intellectual property owners
          could easily report listings that infringe their rights. It is in
          Revaalo’s interest to ensure that infringing products are removed from
          the site
        </p>
        <p>
          In your notice of infringement, you shall be required to identify the
          individual listing which is infringing your intellectual property.
          General notices shall not be accepted.
        </p>
        <p>
          The information requested by the Notice of Infringement is designed to
          ensure that parties reporting products are authorized by the rights
          owners, and to enable Revaalo to easily identify the material or
          listing to be ended.
        </p>
        <h2>Notice of Infringement</h2>
        <p>
          Revaalo Technology Private Limited <br />
          <br />
          _______________________
          <br /> <br />
          _______________________
          <br />
          I, [name] ____________________________ of [address]
          _________________________ do solemnly and sincerely declare as
          follows:
          <br />
          1. I am the owner of certain intellectual property rights, said owner
          being named __________________ ("IP Owner").
          <br />
          2. I have a good faith belief that the listings identified in the
          annexure attached hereto are not authorised by the above IP Owner, its
          agent, or the law and therefore infringe the IP Owner's rights. Please
          expeditiously remove or disable access to the material or products
          claimed to be infringing.
          <br />
          3. I may be contacted at: <br />
          Name ___________________________________________________________
          <br />
          Title & Company
          ________________________________________________________
          <br />
          Address _________________________________________________________{" "}
          <br />
          Email (correspondence)
          ___________________________________________________
          <br /> Telephone/Fax
          _____________________________________________________________ <br />
          Date _________________________________________________________________
          and I make this declaration conscientiously believing it to be true
          and correct.
          <br /> Declared by ______________________________ on [date]
          ___________________________________ in [place]________ <br />
          <br />
          Truthfully, <br />
          <br />
          Signature
        </p>
        <p>
          Note: While sending the Notice of infringement , kindly make sure that
          you include the full details of the entity or the listing you are
          claiming as infringement, the details includes not limited to the URL
          of the listing, the reviews/rating of the listing and such other
          details. For further details contact us at info@revaalo.in
        </p>
      </div>
    </>
  );
};

export default Terms;
