import React from 'react'
import ServiceList from '../components/serviceList/servicelist'

const Servicelisting = () => {
  return (
<>
<ServiceList/>
</>
  )
}

export default Servicelisting