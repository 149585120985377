import React from 'react'
import Detail from '../components/detailservice/detail'

const DetailServiceListingPage = () => {
  
  return (
<>
    <Detail />
</>
  )
}

export default DetailServiceListingPage