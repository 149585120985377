
import React from 'react'
import Profile from '../components/profile/profile'

const ProfilePage = () => {

  return (
<>
    <Profile />
</>
  )
}

export default ProfilePage