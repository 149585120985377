import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Modal
} from "@mui/material";
import Button from "@mui/material/Button";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedIcon from '@mui/icons-material/Verified';
import { NotificationManager } from "react-notifications";
import moment from "moment";
const Profile = () => {
  const [id, setid] = useState(localStorage.getItem("id"));
  const [userDetails, setUserDetails] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setphone] = useState("");
  const [uploadPhoto, setUploadPhoto] = useState();
  const [profileImg, setProfileImg] = useState("");
  const [oldpassword, setoldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const[otp,setOtp]=useState("")
  const[authData,setAuthData]=useState()
  const[token,setToken]=useState("")
  const[authType,setAuthType]=useState("")
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BACKEND + "users/getone", {
        _id: id,
      })
      .then((response) => {
        setUserDetails(response.data.data);
        setName(response.data.data.name);
        setEmail(response.data.data.mail);
        setProfileImg(response.data.data.picture);
        setGender(response.data.data.gender);
        response.data.data.dob !== null
          ? setDob(moment(response.data.data.dob).format("YYYY-MM-DD"))
          : setDob("");
        setphone(response.data.data.phone);
        response.data.data.maritalStatus !== null
          ? setMaritalStatus(response.data.data.maritalStatus)
          : setMaritalStatus("");
        response.data.data.city !== null
          ? setCity(response.data.data.city)
          : setCity("");
        response.data.data.address !== null
          ? setAddress(response.data.data.address)
          : setAddress("");
        setoldPassword("");
      });
  }, []);
  const handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;

    if (nam === "name") {
      setName(val);
    } else if (nam === "email") {
      setEmail(val);
    } else if (nam === "oldpassword") {
      setoldPassword(val);
    } else if (nam === "newpassword") {
      setNewPassword(val);
    } else if (nam === "repassword") {
      setRePassword(val);
    } else if (nam === "dob") {
      setDob(val);
    } else if (nam === "phone") {
      setphone(val);
    } else if (nam === "gender") {
      setGender(val);
    } else if (nam === "maritalStatus") {
      setMaritalStatus(val);
    } else if (nam === "city") {
      setCity(val);
    } else if (nam === "address") {
      setAddress(val);
    } else if(nam==="otp"){
      setOtp(val)
    }
  };
  const handleUpload = (event) => {
    setUploadPhoto(event.target.files[0]);
  };

  const handleSubmit = () => {
    let data = {
      _id: id,
      name: name,
      picture: uploadPhoto,
      gender: gender,
      dob: dob,
      maritalStatus: maritalStatus,
      city: city,
      address: address,
    };

    axios
      .post(process.env.REACT_APP_BACKEND + "auth/userupdate", data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        window.location.reload();
        setProfileImg(response.data.data.picture);
        localStorage.setItem("picture", response.data.data.picture);
        setName(response.data.data.name);
        setEmail(response.data.data.mail);
        setphone(response.data.data.phone);
        NotificationManager.success(response.data.message);
      })
      .catch((error) => {
        NotificationManager.error("Something went wrong");
      });
    const pass = {
      oldPass: oldpassword,
      _id: id,
      newPassword: newpassword,
      confirmPassword: repassword,
    };

    if (
      oldpassword !== "" &&
      oldpassword !== "null" &&
      oldpassword !== undefined
    ) {
      axios
        .post(process.env.REACT_APP_BACKEND + "auth/resetpassword", pass)
        .then((response) => {
          NotificationManager.success("Password Changed SuccessFully");
        })
        .catch((error) => {
          NotificationManager.error(error.response.data.message);
        });
    }
  };
  const verifyOtp=()=>{
    if(authType==="email"){
   let emailValue={
    "hash":token,
    "otp" : otp,
    "id":authData._id,
    "mail":email,
}
      axios
      .post(process.env.REACT_APP_BACKEND + "auth/verifyupdatemail", emailValue)
      .then(response=>{
        console.log(response.data.data)
        setEmail(response.data.data.email)
        setOtp("")
        NotificationManager.success(response.data.message);
        setOpen(false)
      })
  
    }else{
      let phonedata={
        "hash":token,
        "otp" : otp,
        "id":localStorage.getItem("id"),
        "phone":phone,
    }
          axios
          .post(process.env.REACT_APP_BACKEND + "auth/verifyupdatephone", phonedata)
          .then(response=>{
            console.log()
            NotificationManager.success(response.data.message);
            setphone(response.data.data.phone)
            setOtp("")
           setOpen(false)
          })
          .catch(error=>{
            console.log(error)
          })
      

    }

  }
  const handleClose = () => {
    setOpen(false);
  };
  const openModal=(type)=>{
    console.log(type)
    setAuthType(type)
    if(type==="email"){
    const postValue={
      "id":localStorage.getItem("id"),
      "mail":email,
  }
    axios
    .post(process.env.REACT_APP_BACKEND + "auth/updatemail", postValue)
    .then(response=>{
      console.log(response)
      setAuthData(response.data.data)
      setToken(response.data.token)
      setOpen(true)
      NotificationManager.success(response.data.message);
    })
    .catch(error=>{
      NotificationManager.error(error.response.data.error);
    })
  }else{
    const postValue={
      "id":localStorage.getItem("id"),
      "phone":phone,
  }
    axios
    .post(process.env.REACT_APP_BACKEND + "auth/updatephone", postValue)
    .then(response=>{
      console.log(response)
      setAuthData(response.data.data)
      setToken(response.data.token)
      setOpen(true)
      NotificationManager.success(response.data.message);
    })
    .catch(error=>{
      NotificationManager.error(error.response.data.error);
    })

  }

  }

  return (
    <div className="container">
     <div className="row shadow mt-5">
     <p className="fs-5 p-3 m-0">
          <VerifiedIcon sx={{ mr: 1 }} />
          Verify
        </p>
        <div className="col-md-6 p-4">
        <div >
              <TextField
                label="Email"
               fullWidth
                name="email"
                value={email}
                onChange={handleChange}
                // disabled={true}
              />
                    <div style={{textAlign:"end"}}>
                <Button variant="contained" component="span" sx={{ mt: 2 }} onClick={()=>openModal("email")}>
                Verify Email
              </Button>
              </div>
            </div>
        
        </div>
        <div className="col-md-6 p-4">
            <div >
              <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                value={phone}
                onChange={handleChange}
                // disabled={true}
              />
              <div style={{textAlign:"end"}}>
                <Button variant="contained" component="span"  sx={{ mt: 2 }}onClick={()=>openModal("phone")}>
                Verify phone
              </Button>
              </div>
            </div>
          
          </div>
        </div>
      <div className="row shadow mt-5">
        <p className="fs-5 p-3 m-0">
          <AccountCircleOutlinedIcon sx={{ mr: 1 }} />
          My Account
        </p>

        <div className="col-md-3 p-4">
          <div className="text-center">
            <img src={profileImg} width="150px" height="150px" alt="" />

            <label htmlFor="upload-photo" style={{ width: "100%" }}>
              <input
                style={{ display: "none" }}
                id="upload-photo"
                name="upload"
                type="file"
                onChange={handleUpload}
              />

              <Button variant="contained" component="span" sx={{ mt: 2 }}>
                Upload
              </Button>
            </label>
          </div>
          {/* <div className="row mt-4">
            <div >
              <TextField
                label="Email"
                fullWidth
                name="email"
                value={email}
                onChange={handleChange}
                // disabled={true}
              />
            </div>
            <Button variant="contained" component="span" sx={{ mt: 2 }} onClick={()=>openModal("email")}>
                Verify
              </Button>
            <div >
              <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                value={phone}
                onChange={handleChange}
                // disabled={true}
              />
            </div>
            <Button variant="contained" component="span" sx={{ mt: 2 }}onClick={()=>openModal("phone")}>
                Verify
              </Button>
          </div> */}
        </div>
        <div className="col-md-9 p-4">
          <TextField
            label="Name"
            fullWidth
            name="name"
            value={name}
            onChange={handleChange}
          />

        
          <div className="row mt-4">
            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="gender"
                  value={gender}
                  label="gender"
                  onChange={handleChange}
                >
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6">
              <TextField
                type="date"
                label="Date of Birth"
                fullWidth
                name="dob"
                value={dob}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  MaritalStatus
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={maritalStatus}
                  name="maritalStatus"
                  label="MaritalStatus"
                  onChange={handleChange}
                >
                  <MenuItem value="Married">Married</MenuItem>
                  <MenuItem value="Unmarried">Unmarried</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-md-6">
              {/* <TextField
                label="Phone Number"
                fullWidth
                name="phone"
                value={phone}
                onChange={handleChange}
              /> */}

              <TextField
                label="City"
                fullWidth
                name="city"
                value={city}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12">
              <TextField
                label="Address"
                fullWidth
                name="address"
                value={address}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
     


      <div className="row shadow mt-5 mb-3">
        <p className="fs-5 p-3 m-0">
          <SecurityIcon sx={{ mr: 1 }} />
          Password and Security
        </p>
        <div className="col-md-12 text-center">
          <TextField
            type="password"
            label="Current Password"
            sx={{ width: "98%", m: 2 }}
            name="oldpassword"
            value={oldpassword}
            onChange={handleChange}
            autoComplete="off"
          />
          <TextField
            label="New Password"
            type="password"
            sx={{ width: "98%", m: 2 }}
            name="newpassword"
            value={newpassword}
            onChange={handleChange}
            autoComplete="off"
          />
          <TextField
            label=" Repeat New Password"
            type="password"
            sx={{ width: "98%", m: 2 }}
            name="repassword"
            value={repassword}
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="row">
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ mb: 2, width: "20%" }}
        >
          Save Changes
        </Button>
      </div>
      {/*----------------------------------------- Modal for verify otp------------------------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">Enter Otp</h4>
            <TextField
                label="Otp"
                fullWidth
                name="otp"
                value={otp}
                onChange={handleChange}
            
              />
            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "40%", mt: 4, mr: 4 }}
                onClick={verifyOtp}
              >
                Verify
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "40%", mt: 4 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
