import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.css";
import preschool from "../../assets/preschool.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Rating,
  TextField,
  Box,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CheckIcon from "@mui/icons-material/Check";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Button from "@mui/material/Button";

import Pagination from "@mui/material/Pagination";
import { NotificationManager } from "react-notifications";
import { useLocation, useParams } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import Axios from "../../../axios";
import EmailIcon from "@mui/icons-material/Email";
import Login from "../Login/login";
import Register from "../Register/register";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CloseIcon from "@mui/icons-material/Close";
import GoogleAds from "../../../GoogleAds";
import Search from "../Search/Search";
import SimpleMap from "../serviceList/Map";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import moment from "moment";
import Avatar from "../../assets/avatar-raavalo.png";
const styleTab = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  overflowX: "hidden",
};
const Detail = () => {
  const [detailService, setDetailService] = useState([]);
  const [value, setValue] = useState(5);
  const [open, setOpen] = useState(false);
  const [review, setReview] = useState("");
  const [userId, setUserId] = useState("");
  const [img, setImg] = useState([]);
  const [rating, setRating] = useState(0);
  const [serviceId, setServiceId] = useState("");
  const [title, setTitle] = useState("");
  const [reviewList, setreviewList] = useState([]);
  const [page, setPage] = useState(1);
  const [token, setToken] = useState();
  const [pageCount, setPageCount] = useState(0);
  const { service } = useParams();
  const [otherDetails, setOtherDetails] = useState([]);
  const [openLogin, setOpenLogin] = useState(false);
  const [valueTab, setValueTab] = useState("one");
  const [type, setType] = useState("");
  const [reviewId, setreviewId] = useState("");
  const [cityId, setCityId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const { state } = useLocation();

  useEffect(() => {
    if (service === "service") {
      axios
        .get(
          process.env.REACT_APP_BACKEND +
            `services/getone${window.location.pathname}`
        )
        .then((res) => {
          setDetailService(res.data.data);
          setServiceId(res.data && res.data.data && res.data.data._id);
          setOtherDetails(res.data.data.otherDetails.split(","));
          localStorage.setItem("city", res.data.data.city_name);
          setCityId(res.data.data.city_ref._id);

          axios
            .post(process.env.REACT_APP_BACKEND + "review/listing", {
              //  "service_ref" : '631b1bafdd12d8a8f3913997',
              service_ref: res.data && res.data.data && res.data.data._id,
              reviewId: state?.id,
            })
            .then((res) => {
              setPageCount(res.data.data.pages);
              setreviewList(res.data.data.data);
            });
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_BACKEND +
            `products/getone${window.location.pathname}`
        )
        .then((res) => {
          setDetailService(res.data.data);
          setServiceId(res.data && res.data.data && res.data.data._id);
          setOtherDetails(res.data.data.otherDetails.split(","));
          if (
            res.data.data.city_name !== null &&
            res.data.data.city_name !== undefined &&
            res.data.data.city_name !== ""
          ) {
            localStorage.setItem("city", res.data.data.city_name);
          }
          axios
            .post(process.env.REACT_APP_BACKEND + "review/listing", {
              service_ref: res.data && res.data.data && res.data.data._id,
              reviewId: state?.id,
            })
            .then((res) => {
              setreviewList(res.data.data.data);
              setPageCount(res.data.data.pages);
            });
        });
    }
    setUserId(localStorage.getItem("id"));

    setToken(localStorage.getItem("token"));
  }, [window.location.pathname]);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChange = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    if (name === "title") {
      setTitle(val);
    } else if (name === "rating") {
      setRating(val);
    } else if (name === "review") {
      setReview(val);
    }
  };

  const handleOpen = () => {
    if (token !== null && token !== undefined && token !== "") {
      setOpen(true);
    } else setOpenLogin(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteModal(false);
  };
  const handleCloseTab = (value) => {
    // window.location.reload()
    setOpenLogin(false);
  };
  const handleSubmitReview = () => {
    const formData = new FormData();
    if (type === "edit") {
      formData.append("service_ref", serviceId);
      formData.append("rating", rating);
      formData.append("review", review);
      formData.append("user_ref", userId);
      formData.append("title", title);
      formData.append("_id", reviewId);
      img.map((file) => {
        formData.append("img", file);
      });

      Axios.post(process.env.REACT_APP_BACKEND + "review/update", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setPageCount(response.data.data.pages);
        setreviewList(response.data.data.data);
        setType("");
        setTitle("");
        setRating(0);
        setReview("");
        setImg([]);
        handleClose();
        NotificationManager.success(response.data.message);
      });
    } else {
      formData.append("service_ref", serviceId);
      formData.append("rating", rating);
      formData.append("review", review);
      formData.append("user_ref", userId);
      formData.append("title", title);
      img.map((file) => {
        formData.append("img", file);
      });
      formData.append("city_ref", cityId);
      if (
        rating !== null &&
        rating !== undefined &&
        rating !== "" &&
        rating !== 0 &&
        review !== null &&
        review !== undefined &&
        review !== "" &&
        title !== null &&
        title !== undefined &&
        title !== ""
      ) {
        Axios.post(process.env.REACT_APP_BACKEND + "review/create", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }).then((response) => {
          if (response.data.success === true)
            NotificationManager.success(response.data.message);
          setTitle("");
          setRating(0);
          setReview("");
          setImg([]);
          setreviewList(response.data.data.data);

          handleClose();
        });
      } else {
        NotificationManager.warning("Please Fill all the required Fields");
      }
    }
  };
  const handlePageChange = (e, p) => {
    setPage(p);
    const data = {
      limit: 10,
      page: p,
      // "service_ref":'631b1bafdd12d8a8f3913997',
      service_ref: serviceId,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "review/listing", data)
      .then((res) => {
        setreviewList(res.data.data.data);
      });
  };

  const handleImageChange = (e) => {
    let files = Array.prototype.slice.call(e.target.files);
    setImg(files);
  };
  const handleCheck = (event) => {
    // console.log(event.target.checked, "Shubham");
  };
  const handleDeleteReview = (id) => {
    Axios.post(process.env.REACT_APP_BACKEND + "review/delete", {
      _id: id,
      user_ref: userId,
    }).then((response) => {
      setreviewList(response.data.data.data);
      setPageCount(response.data.data.pages);

      handleClose();
      NotificationManager.success(response.data.message);
    });
  };
  const handleEditReview = (id, type, serviceid) => {
    handleOpen();
    Axios.post(process.env.REACT_APP_BACKEND + "review/getone", {
      service_ref: serviceid,
      _id: id,
    }).then((response) => {
      setTitle(response?.data?.data?.title);
      setRating(response?.data?.data?.ratings);
      setReview(response?.data?.data?.description);
      setreviewId(id);
      setType("edit");
    });
  };
  const handleDeleteModal = (id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };
  const convertDateToDays = (date) => {
    const date1 = new Date();

    const newDate = String(date1).slice(8, 10);

    const date3 = date.slice(8, 10);

    if (date3 === newDate) {
      return "Today";
    } else {
      const date2 = new Date(date);
      const diffTime = Math.abs(date2 - date1);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 30) {
        const day = `${diffDays} days ago`;
        return day;
      } else {
        return moment(date).format("DD MMM YYYY");
      }
    }
  };

  return (
    <>
      <div className="container mt-4 mb-3">
        <div className="d-serach-Cont">
          <Search />
        </div>
      </div>
      <div className="detailcontainer ">
        {/* <img src={detailService?.category_ref?.images} className="detailimage" /> */}
        <img src="" className="detailimage" />

        <div className="overlaydetail ">
          <div className=" row  detail_body ">
            <div className="col-md-2  p-2 main-img ">
              <img
                src={
                  !detailService && detailService.images.length > 0
                    ? detailService?.images && detailService?.images[0]
                    : detailService &&
                      detailService.category_ref &&
                      detailService.category_ref.images
                }
                alt=""
                className="pre-img shadow-sm p-2 "
                style={{ maxWidth: "100% " }}
              />
            </div>
            <div className="col-md-4  ">
              <div className="p-2 ms-2 text-start mt-3">
                <h5 className="title">
                  <b>{detailService.title} </b>
                </h5>
              </div>
              {detailService.areaName !== null &&
              detailService.areaName !== undefined &&
              detailService.areaName !== "" ? (
                <p className="mt-3 ms-2">
                  <LocationOnIcon />
                  <span className="ms-1">
                    {" "}
                    {detailService.areaName}, {detailService.city_name}
                  </span>
                </p>
              ) : null}
              <div className="d-flex align-items-center   mt-3 rat_cont ">
                <div className=" d-flex align-items-center text-center ms-3 ">
                  <p className=" mt-3 me-2 ratingtag">
                    {detailService && detailService?.avg_rating !== null
                      ? detailService?.avg_rating
                      : 0}
                  </p>

                  <Rating
                    name="read-only"
                    value={
                      detailService?.avg_rating !== undefined
                        ? detailService && detailService.avg_rating
                        : 0
                    }
                    precision={0.5}
                    readOnly
                  />
                </div>
                <div className=" ms-2">
                  <div
                    className=" rounded  text-white d-flex align-items-center justify-content-center "
                    style={{ background: "#38b653", padding: "2px 5px" }}
                  >
                    <p className="m-0">
                      <CheckIcon />
                    </p>
                    <p
                      className="ms-2 m-0"
                      style={{ fontWeight: "600", fontSize: "12px" }}
                    >
                      Verified
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {detailService &&
            detailService.images &&
            detailService.images.length > 0 ? (
              <div className="col-md-6">
                {detailService &&
                  detailService.images
                    .slice(0, 3)
                    .map((item, index) => (
                      <img
                        key={index}
                        src={item}
                        alt=""
                        className=" shadow-sm p-2 ms-3 d-img"
                      />
                    ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <GoogleAds slot="3951276679" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 p-2">
            <div className="p-3">
              <h3 style={{ color: "#01a1de" }}>Description</h3>
              <p className="mt-3 p-1">
                {detailService &&
                detailService.overview !== null &&
                detailService &&
                detailService.overview !== undefined ? (
                  `${detailService.overview}`
                ) : (
                  <> </>
                )}
              </p>
            </div>
            {/* ---------------------------------review Section-------------------------------- */}
            <div className="review">
              {reviewList.map((review, index) => (
                <div className=" shadow-sm mb-4 p-2" key={review._id}>
                  <div className="row " key={review._id}>
                    <div className="col-md-2 text-center">
                      <div className="p-2">
                        <img
                          src={
                            review &&
                            review.user_ref &&
                            review.user_ref.picture !== null &&
                            review &&
                            review.user_ref &&
                            review.user_ref.picture !== ""
                              ? review &&
                                review.user_ref &&
                                review.user_ref.picture !== null
                              : Avatar
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.onerror = undefined;
                            e.target.src = { Avatar };
                          }}
                          alt=""
                          width="80px"
                          height="80px"
                          className="rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="col-md-10 p-2 ">
                      <div className=" d-flex align-content-center justify-content-between">
                        <div className="m-0">
                          <p className="r_title">{review.title} </p>
                          <p className="r_name">
                            {review && review.user_ref && review.user_ref.name}
                          </p>
                        </div>

                        {userId === review?.user_ref?._id ? (
                          <div>
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleDeleteModal(review._id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                handleEditReview(
                                  review._id,
                                  type,
                                  review?.service_ref
                                )
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="d-flex justify-content-between align-content-center pe-3">
                        <div className="d-flex ">
                          <p className="ratingtag">{review?.ratings}</p>
                          <Rating
                            name="read-only"
                            value={review.ratings}
                            readOnly
                            className=" ms-1"
                          />{" "}
                        </div>
                        <span>{convertDateToDays(review?.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 px-4">
                      <p className="px-2">{review.description}</p>

                      <>
                        {review &&
                          review.img &&
                          review.img
                            .slice(0, 3)
                            .map((item, index) => (
                              <img
                                src={item}
                                alt=""
                                height="auto"
                                key={index}
                                className="shadow-sm p-2 ms-3 r-img"
                              />
                            ))}
                      </>

                      {/* <FormControlLabel
                      control={
                        <Checkbox
                          icon={<FavoriteBorderIcon />}
                          checkedIcon={<FavoriteIcon />}
                          name="checkedH"
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "red",
                            },
                          }}
                          onChange={handleCheck}
                        />
                      }
                      label="12"
                    /> */}
                    </div>
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-center mt-4 p-0">
                <Pagination
                  count={pageCount}
                  page={page}
                  variant="outlined"
                  onChange={handlePageChange}
                  shape="rounded"
                  color="primary"
                />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="mt-5 p-2">
              <Button
                variant="contained"
                className="w-100 p-3 btn_review"
                onClick={handleOpen}
              >
                Write Review <ArrowRightAltIcon />
              </Button>
            </div>

            <div className="mt-4 p-2 shadow-sm other ">
              <h5
                className=" m-0  p-0 text-center"
                style={{
                  color: "#01a1de",
                  fontWeight: "600",
                }}
              >
                {detailService &&
                  detailService.category_ref &&
                  detailService.category_ref.title}{" "}
                Details
              </h5>

              <hr className="p-0 " />
              <div className="other_section1">
                {detailService.address !== null ? (
                  <div className="d-flex align-items-start ">
                    <p>
                      <LocationOnIcon sx={{ color: "#01a1de" }} />{" "}
                    </p>
                    <p className="ms-3"> {detailService.address}</p>
                  </div>
                ) : null}
                {detailService.contact_person !== null ? (
                  <div className="d-flex align-items-start ">
                    <p>
                      <PhoneIcon sx={{ color: "#01a1de" }} />{" "}
                    </p>
                    <p className="ms-3"> {detailService.contact_person}</p>
                  </div>
                ) : null}
                {detailService.mail !== null ? (
                  <div className="d-flex align-items-start ">
                    <p>
                      <EmailIcon sx={{ color: "#01a1de" }} />{" "}
                    </p>
                    <p className="ms-3"> {detailService.mail}</p>
                  </div>
                ) : null}
                {detailService.phone !== null ? (
                  <div className="d-flex align-items-start ">
                    <p>
                      <PhoneIcon sx={{ color: "#01a1de" }} />{" "}
                    </p>
                    <p className="ms-3"> {detailService.phone}</p>
                  </div>
                ) : null}
                <div className=" ">
                  <h5>
                    <SettingsIcon sx={{ color: "#01a1de" }} /> Other Details
                  </h5>
                  {otherDetails.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))}
                </div>
              </div>
            </div>

            <p className="mt-3 p-2">
              {/* <iframe
                title="Maps"
                className="embed-responsive-item w-100 map "
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497699.99742217135!2d77.35072793724844!3d12.953847708182487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1626291694647!5m2!1sen!2sin"
              ></iframe> */}
              <SimpleMap />
            </p>

            <div>
              <GoogleAds slot=" 4788881479" />
            </div>
          </div>
        </div>
      </div>
      {/*------------------------------------- Add EDit MOdal------------------------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">Write and Rate Review</h4>
            <div className="d-flex text-center justify-content-between mt-4">
              <p>
                <em style={{ color: "red" }}>*</em> Ratings
              </p>
              <Rating name="rating" value={rating} onChange={handleChange} />
            </div>

            <div className="d-flex text-center justify-content-between align-items-center mt-4">
              <p>
                <em style={{ color: "red" }}>*</em> Title
              </p>

              <TextField
                label="Title"
                sx={{ width: "90%" }}
                name="title"
                value={title}
                onChange={handleChange}
              />
            </div>
            <div className="d-flex text-center justify-content-between align-items-center mt-4">
              <p className="me-2">
                <em style={{ color: "red" }}>*</em> Review
              </p>

              <textarea
                id=""
                cols="60"
                rows="5"
                style={{ resize: "none", outline: "none" }}
                onChange={handleChange}
                value={review}
                name="review"
              ></textarea>
            </div>
            <div className="d-flex text-center justify-content-between align-items-center mt-4">
              <p>Image</p>

              <input
                accept="image/*"
                // className={classes.input}
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="raised" component="span">
                  Upload
                </Button>
              </label>

              <span>
                {img.map((item, index) => (
                  <span key={index}>{item.name},</span>
                ))}
              </span>
            </div>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "90%", mt: 4 }}
                onClick={handleSubmitReview}
              >
                Submit Review
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {/* ---------------------------------Login/Register Modal=------------------------------------------------ */}
      <Modal
        open={openLogin}
        onClose={handleCloseTab}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTab}>
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label="Login" value="one" />
                <Tab label="Register" value="two" />
                <Button
                  onClick={handleCloseTab}
                  style={{ marginLeft: "15rem", color: "grey" }}
                >
                  <CloseIcon />
                </Button>
              </TabList>
            </Box>
            <TabPanel value="one">
              <Login close={handleCloseTab} />
            </TabPanel>
            <TabPanel value="two">
              <Register close={handleCloseTab} />
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
      {/* -------------------------------------Delete Modal---------------------------- */}
      <Modal
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">
              Are you Sure you want to delete the review?
            </h4>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "40%", mt: 4, mr: 4 }}
                onClick={() => handleDeleteReview(deleteId)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "40%", mt: 4 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Detail;
