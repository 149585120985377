import { Autocomplete, Card, FormControlLabel, Checkbox } from "@mui/material";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { Button, TextField, Modal, Box } from "@mui/material";
import Search from "../Search/Search";
import "./style.css";
import axios from "axios";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import Login from "../Login/login";
import Register from "../Register/register";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Img1 from "../../assets/dumbcomment.jpg";
import Axios from "../../../axios";

const styleTab = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "scroll",
  overflowX: "hidden",
};
const DiscussionList = () => {
  const [discussionList, setDiscussionList] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [discussionCategorylist, setDiscussionCatergoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [valueTab, setValueTab] = useState("one");
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const userid = localStorage.getItem("id");
  const { slug } = useParams();
  const [token, setToken] = useState();
  const [deleteId, setDeleteId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [type, setType] = useState("");
  const [catId, setCatId] = useState("");
  const [likeStatus, setLikeStatus] = useState(false);
  const [discussionImages, setDiscussionImages] = useState([]);

  const handleNavigate = (id) => {
    const path = `/${id}`;
    // const path = `/discussion/${id}`;

    navigate(path);
  };
  useEffect(() => {
    if (slug) {
      axios
        .post(
          process.env.REACT_APP_BACKEND + "discussioncategory/getdiscussions",
          { alias: slug }
        )
        .then((response) => {
          setDiscussionList(response.data.data.data);
          setCount(response.data.data.pages);
        });
    } else if (window.location.pathname === "/discussion") {
      axios
        .post(process.env.REACT_APP_BACKEND + "discussion/listing", {
          user_ref: userid,
        })
        .then((response) => {
          setDiscussionList(response.data.data.data);
          setCount(response.data.data.pages);
        });
    }
    axios
      .post(
        process.env.REACT_APP_BACKEND + "discussioncategory/listing/category",
        { type: "show" }
      )
      .then((response) => {
        setDiscussionCatergoryList(response.data.data);
      });

    setToken(localStorage.getItem("token"));
  }, [slug]);
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChange = (e, p) => {
    setPage(p);
    const data = {
      limit: 10,
      page: p,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "discussion/listing", data)
      .then((response) => {
        setDiscussionList(response.data.data.data);
        window.scrollTo(0, 0);
      });
  };
  const handleChangeNewDiscussion = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    } else if (name === "discussionImages") {
      let files = Array.prototype.slice.call(e.target.files);

      setDiscussionImages(files);
    }
  };
  const handleSubmitDiscussion = () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", description);
    formData.append("user_ref", userid);
    formData.append("category_ref", categoryValue?._id);
    discussionImages.map((file) => {
      formData.append("images", file);
    });
    if (type === "edit") {
      formData.append("_id", catId);
      Axios.post(
        process.env.REACT_APP_BACKEND + "discussion/update",
        formData
      ).then((response) => {
        setTitle("");
        setDescription("");
        setType("");
        setDiscussionImages([]);
        setDiscussionList(response.data.data.data);
        setCount(response.data.data.pages);
        handleClose();
        NotificationManager.success(response.data.message);
      });
    } else {
      if (
        title !== "" &&
        description !== "" &&
        categoryValue !== null &&
        categoryValue !== ""
      ) {
        Axios.post(
          process.env.REACT_APP_BACKEND + "discussion/create",
          formData
        ).then((response) => {
          setTitle("");
          setDescription("");
          setCategoryValue("");
          setDiscussionImages([]);
          NotificationManager.success(response.data.message);
          setDiscussionList(response.data.data.data);
          setCount(response.data.data.pages);
        });

        handleClose();
      } else {
        NotificationManager.warning("Please fill all the required Fields");
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
    setOpenLogin(false);
    setDeleteModal(false);
  };
  const handleModalOpen = () => {
    if (token !== null && token !== undefined && token !== "") {
      setOpen(true);
      setType("");
      setTitle("");
      setCategoryValue("");
      setDescription("");
    } else setOpenLogin(true);
  };
  const handleChnageCategory = (event, value) => {
    setCategoryValue(value);
  };
  const handleClickCategory = (slug, id) => {
    let path = `/${slug}`;
    navigate(path);
    axios
      .post(
        process.env.REACT_APP_BACKEND + "discussioncategory/getdiscussions",
        { alias: id }
      )
      .then((response) => {
        setDiscussionList(response.data.data.data);
        setCount(response.data.data.pages);
      });
  };
  const handleClickDiscussion = () => {
    navigate("/discussion");
    axios
      .post(process.env.REACT_APP_BACKEND + "discussion/listing")
      .then((response) => {
        setDiscussionList(response.data.data.data);
        setCount(response.data.data.pages);
      });
  };
  const handleDeleteDiscussion = (id) => {
    Axios.post(process.env.REACT_APP_BACKEND + "discussion/delete", {
      _id: deleteId,
      user_ref: userid,
    }).then((response) => {
      setDiscussionList(response.data.data.data);
      setCount(response.data.data.pages);
      handleClose();
      NotificationManager.success(response.data.message);
    });
  };
  const handleDeleteModal = (id, e) => {
    setDeleteModal(true);
    setDeleteId(id);
  };
  const handleEditDiscussion = (alias) => {
    setOpen(true);

    axios
      .post(process.env.REACT_APP_BACKEND + `discussion/getone/${alias}`)
      .then((response) => {
        setCategoryValue(response?.data?.data?.data?.category_ref);
        setTitle(response?.data?.data?.data?.title);
        setDescription(response?.data?.data.data?.content);
        setCatId(response?.data?.data?.data?._id);
        setType("edit");
      });
  };
  // function For Like a discussion
  const handleLike = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    axios
      .post(process.env.REACT_APP_BACKEND + "discussion/like_dislike", {
        _id: id,
        user_ref: userid,
      })
      .then((resp) => {
        setLikeStatus(resp.data.data.likeStatus);
      });
  };

  return (
    <div className="container pt-5 pb-5">
      <div className="ps-3 pe-3">{/* <Search /> */}</div>
      <div className="d-flex justify-content-between align-items-center mb-4 ">
        <h3>Discussion</h3>
        <Button variant="contained" onClick={handleModalOpen}>
          New Discussion
        </Button>
      </div>
      <div className="row">
        <div className="col-md-3">
          <h4>Category</h4>
          <div
            className="mt- shadow"
            style={{ height: "300px", overflowY: "scroll", padding: "8px" }}
          >
            <Card className="p-0">
              <p className="category_border" onClick={handleClickDiscussion}>
                All Discussions
              </p>

              {discussionCategorylist.map((item, index) => (
                <p
                  className={
                    item.alias === slug ? "categoryActive" : "category_border"
                  }
                  onClick={() => handleClickCategory(item.slug, item.alias)}
                  key={index}
                >
                  {item.name}
                </p>
              ))}
            </Card>
          </div>
        </div>
        <div className="col-md-9">
          <div className="p-0 mb-3">
            {discussionList.map((item, index) => (
              <div className="mb-2 shadow-sm" key={index}>
                {userid === item?.user_ref?._id ? (
                  <div className="text-end ">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteModal(item._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEditDiscussion(item.alias)}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className="row p-4 discuss_bb "
                  key={index}
                  onClick={() => handleNavigate(item.alias)}
                >
                  <div className="col-md-2 p-0">
                    <img
                      src={
                        item?.images !== null &&
                        item?.images !== undefined &&
                        item?.images !== ""
                          ? item?.images
                          : Img1
                      }
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="col-md-10 ">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="discuss_font">{item?.user_ref?.name}</p>
                      <p className="discuss_font d_date">
                        {moment(item.createdAt).format("DD MMM YYYY")}
                      </p>
                    </div>
                    <h6 className="discuss_title mb-2">{item.title}</h6>
                    <p className="d_content">{item?.content}</p>
                    <div className="row">
                      <div className="col-md-8">
                        <div className="d-flex align-items-center gap-2">
                          {item?.tags.map((item, index) => (
                            <p className="discuss_tags" key={index}>
                              {item}
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-4">
                        {/* <IconButton onClick={(e) => handleLike(e, item?._id)}>
                          {likeStatus === false ? (
                            <ThumbUpOffAltIcon />
                          ) : (
                            <ThumbUpIcon />
                          )}
                        </IconButton> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center  mt-4 mb-4 me-0 ms-0">
            <Pagination
              count={count}
              page={page}
              variant="outlined"
              onChange={handleChange}
              shape="rounded"
              color="primary"
            />
          </div>
        </div>
      </div>
      {/* ----------------------EDit Delete Modal----------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">{type === "edit" ? "Edit" : "Create New"}</h4>

            <div className=" mt-4">
              <p className="text-start">
                <em style={{ color: "red" }}>*</em> Title
              </p>

              <TextField
                label="Title"
                fullWidth
                name="title"
                value={title}
                onChange={handleChangeNewDiscussion}
              />
            </div>
            <div className=" mt-4">
              <p className="me-2 text-start">
                <em style={{ color: "red" }}>*</em> Category
              </p>

              <Autocomplete
                fullWidth
                // freeSolo
                autoHighlight
                // value={categoryValue?.name}
                value={
                  discussionCategorylist.find(
                    (option) => option?.name === categoryValue?.name
                  ) || null
                }
                options={discussionCategorylist}
                getOptionLabel={(productList) =>
                  productList?.name !== null ? productList?.name : <></>
                }
                onChange={handleChnageCategory}
                renderInput={(params) => (
                  <TextField {...params} label=" Select Category" />
                )}
              />
            </div>

            <div className=" mt-4">
              <p className="text-start">
                <em style={{ color: "red" }}>*</em> Upload Images
              </p>

              <Button variant="contained" component="label">
                Upload
                <input
                  hidden
                  accept="image/*"
                  // multiple
                  type="file"
                  name="discussionImages"
                  onChange={handleChangeNewDiscussion}
                />
              </Button>
              {discussionImages.map((item, index) => (
                <span className="ms-2" key={index}>
                  {item.name}
                </span>
              ))}
            </div>
            <div className=" mt-4">
              <p className="me-2 text-start">
                <em style={{ color: "red" }}>*</em> Description
              </p>

              <textarea
                id=""
                cols="60"
                rows="5"
                style={{ resize: "none", outline: "none" }}
                onChange={handleChangeNewDiscussion}
                value={description}
                name="description"
              ></textarea>
            </div>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "90%", mt: 4 }}
                onClick={handleSubmitDiscussion}
              >
                {type === "edit" ? "Submit" : "Create New Discussion"}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openLogin}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleTab}>
          <TabContext value={valueTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeTab}
                aria-label="lab API tabs example"
              >
                <Tab label="Login" value="one" />
                <Tab label="Register" value="two" />
                <Button
                  onClick={handleClose}
                  style={{ marginLeft: "15rem", color: "grey" }}
                >
                  <CloseIcon />
                </Button>
              </TabList>
            </Box>
            <TabPanel value="one">
              <Login close={handleClose} />
            </TabPanel>
            <TabPanel value="two">
              <Register close={handleClose} />
            </TabPanel>
          </TabContext>
        </Box>
      </Modal>
      {/* -------------------------------Delete Modal-------------------------- */}
      <Modal
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">
              Are you Sure you want to delete the Discussion?
            </h4>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "40%", mt: 4, mr: 4 }}
                onClick={() => handleDeleteDiscussion()}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "40%", mt: 4 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DiscussionList;
