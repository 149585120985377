import React from "react";
import map from "../../assets/map.png";
import { Link } from "react-router-dom";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import GoogleIcon from "@mui/icons-material/Google";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { TextField, Button } from "@mui/material";

const ContactUs = () => {
  return (
    <div className="container cu_main">
      <h1 className="cu_h1 p-0 ms-0">Contact</h1>

      <div className="row cu_cont1">
        <div className="col-md-6 p-3 ps-5">
          <h3 className="mt-4 cu_h3 ">Our Office</h3>
          <Divider className="cu_divider" />
          <p className="cu_f1">
            Revaalo Technology Pvt. Ltd. <br /> JP Nagar, <br />
            Bangalore 560078, Karnataka
          </p>
          <p className="cu_f1"> Landline: 080-42007054</p>
          <p>
            <Link className="cu_link">info@revaalo.in </Link>
          </p>
          <div>
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              href="https://www.facebook.com/revaalobengaluru"
            >
              <FacebookSharpIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <GoogleIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <LinkedInIcon />
            </IconButton>
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
              href="https://twitter.com/Revaalo"
            >
              <TwitterIcon />
            </IconButton>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <img src={map} alt="" width="100%" />
        </div>
      </div>
      {/* <div className="container cu_pd">
        <p className="cu_f3">Any question ? Feel free to contact us !</p>

        <div className="row">
          <div className="col-md-12 mb-3">
            <TextField
              id="outlined-basic"
              fullWidth
              label="Name"
              variant="outlined"
            />
          </div>
          <div className="col-md-6">
            <TextField
              id="outlined-basic"
              fullWidth
              label="Phone Number"
              variant="outlined"
            />
          </div>
          <div className="col-md-6">
            <TextField
              id="outlined-basic"
              fullWidth
              label="Email"
              variant="outlined"
            />
          </div>
          <div className="col-md-12 mt-3">
            <TextField
              id="outlined-basic"
              fullWidth
              label="Message"
              variant="outlined"
              multiline
              rows={4}
            />
          </div>
          <div className="col-md-12 mt-3 ">
            <Button variant="contained" fullWidth>
              Submit
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default ContactUs;
