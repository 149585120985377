import React from "react";
import { Card } from "@mui/material";
import Img from "../../assets/cube.png";
import Img1 from "../../assets/eye.png";
import Img2 from "../../assets/hands.png";

const AboutUs = () => {
  return (
    <>
      <div className="container">
        <div className="text-center mt-5 mb-5">
          <h1>Welcome to Revaalo</h1>
          <Card>
            <h3 className="mt-3 mb-3">
              revaalo - best local businesses recommended by friends
            </h3>
            <div className="conatiner  text-center">
              <div className="row">
                <div className="col-md-4">
                  <div className="rb-ab-img">
                    <img src={Img} alt="" />
                  </div>
                  <p className="rb-ab-p" style={{ fontWeight: "600" }}>
                    19,000+
                  </p>
                  <p className="rb-ab-p">Products</p>
                </div>
                <div className="col-md-4">
                  <div className="rb-ab-img">
                    <img src={Img2} alt="" />
                  </div>
                  <p className="rb-ab-p" style={{ fontWeight: "600" }}>
                    590,000+
                  </p>
                  <p className="rb-ab-p">Services</p>
                </div>
                <div className="col-md-4">
                  <div className="rb-ab-img">
                    <img src={Img1} alt="" />
                  </div>
                  <p className="rb-ab-p" style={{ fontWeight: "600" }}>
                    1,020,000+
                  </p>
                  <p className="rb-ab-p">Visits</p>
                </div>
              </div>
              <hr />
              <p className="px-5 rb-ab-p  mt-5">
                We are the fastest growing products and services listing company{" "}
                <br />
                With more than 600,000++ business listings in 46 categories, we
                aim at helping consumers make informed decisions by <br />{" "}
                providing them unbiased trusted consumer reviews <br /> The
                driving force behind the revaalo is to the enthusiastic team
                which makes sure that everything we deliver is of great standard{" "}
                <br /> Revaalo is thriving at becoming the leader in the user
                generated content space and is working towards making the
                content more accurate every day
              </p>
              <p className="rb-ab-p " style={{ fontWeight: "600" }}>
                Some of the things we provide
              </p>
              <p className="rb-ab-p ">
                The details of the best products and services around you <br />{" "}
                Trusted unbiased end user reviews <br /> Allow Sharing of your
                review with the world <br /> Details of discounts, sales,
                special day and much more, in the form of events <br />{" "}
                Connection between consumers and businesses <br /> Helps
                businesses to improve their market presence and provide better
                service <br /> Revaalo works to best of its capacity to make
                sure that the reviews are genuine so that user can make the best
                informed decisions
              </p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
