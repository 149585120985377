import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './approuter.jsx';
import {NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';




ReactDOM.render(
  // <React.StrictMode>
   <>
   <AppRouter/>
   < NotificationContainer/>
   </>,
  // </React.StrictMode>,
  document.getElementById('root')
);


