import React from "react";
import GoogleMapReact from "google-map-react";

const AnyReactComponent = ({ text }) => (
  <div style={{ color: "red", fontSize: "20px" }}>{text}</div>
);

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 12.91961,
      lng: 77.54437,
    },
    zoom: 10,
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent lat={12.91961} lng={77.54437} text="Revaalo labs" />
      </GoogleMapReact>
    </div>
  );
}
