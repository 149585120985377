import React, { useEffect, useState } from "react";
import Search from "../Search/Search";
import { Card, TextField, Button, Modal } from "@mui/material";
import { NotificationManager } from "react-notifications";
import IconButton from "@mui/material/IconButton";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import SendIcon from "@mui/icons-material/Send";
import ShareIcon from "@mui/icons-material/Share";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Axios from "../../../axios";
import moment from "moment";
import avatar from "../../assets/avatar-raavalo.png";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
const BlogItem = () => {
  const userId = localStorage.getItem("id");
  const [blogDetails, setBlogDetails] = useState();
  const [likeStatus, setLikeStatus] = useState(false);
  const [comment, setCommet] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [totalComments, setTotalComments] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [type, setType] = useState("");
  const [commentId, setCommetId] = useState("");
  const [editComment, setEditComment] = useState("");
  const [noOfLikes, setNoOfLikes] = useState("");
  const [recentBlogs, setRecentBlogs] = useState([]);
  const Navigate = useNavigate();
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_BACKEND +
          `blogs/getone${window.location.pathname}`,
        {
          user_ref: userId,
        }
      )
      .then((resp) => {
        setBlogDetails(resp.data.data.data);
        setTotalComments(resp.data.data.data.no_of_comments);
        setNoOfLikes(resp.data.data.data.no_of_likes);

        axios
          .post(process.env.REACT_APP_BACKEND + "blogs/commentgetall", {
            _id: resp.data.data.data._id,
          })
          .then((resp) => {
            setCommentList(resp.data.data.comments);
          });
      });

    axios
      .get(process.env.REACT_APP_BACKEND + "blogs/latestblogs")
      .then((response) => {
        setRecentBlogs(response.data.data);
      });
  }, []);
  // hanlde chnage for comments
  const handleComments = (e) => {
    let val = e.target.value;

    if (type === "edit") {
      setEditComment(val);
    } else {
      setCommet(val);
    }
  };
  //delet Comments
  const handleClose = () => {
    setDeleteModal(false);
  };
  // Api
  const handleDeleteComments = () => {
    Axios.post(process.env.REACT_APP_BACKEND + "blogs/commentdelete", {
      _id: blogDetails?._id,
      comment_id: deleteId,
    }).then((resp) => {
      setCommentList(resp?.data?.data?.comments);
      setTotalComments(resp?.data?.data?.no_of_comments);
      handleClose();
      NotificationManager.success(resp?.data?.message);
    });
  };
  const handleDeleteModal = (e, id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };

  //Like dislike for blogs
  const handleLike = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    Axios.post(process.env.REACT_APP_BACKEND + "blogs/like_dislike", {
      _id: id,
      user_ref: userId,
    })
      .then((resp) => {
        setLikeStatus(resp.data.data.likeStatus);
        setNoOfLikes(resp.data.data.data.no_of_likes);
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  };
  // Comments Addd
  const handleSubmitComment = () => {
    if (type === "edit") {
      Axios.post(process.env.REACT_APP_BACKEND + "blogs/commentupdate", {
        _id: blogDetails?._id,
        comment_id: commentId,
        comment: editComment,
      }).then((resp) => {
        setCommentList(resp?.data?.data?.comments);
        NotificationManager.success(resp?.data?.message);
        setType("");
      });
    } else {
      if (comment !== "" && comment != null && comment !== undefined) {
        Axios.post(process.env.REACT_APP_BACKEND + "blogs/commentadd", {
          _id: blogDetails?._id,
          user_ref: userId,
          comment: comment,
        })
          .then((resp) => {
            setCommet("");
            setCommentList(resp?.data?.data?.comments);
            setTotalComments(resp?.data?.data?.no_of_comments);
            NotificationManager.success(resp?.data?.message);
          })
          .catch((error) => {
            NotificationManager.error(error?.response?.data?.message);
          });
      }
    }
  };
  const handleEditComments = (e, id) => {
    setType("edit");
    setCommetId(id);
    axios
      .post(process.env.REACT_APP_BACKEND + "blogs/commentgetone", {
        _id: blogDetails?._id,
        comment_id: id,
      })
      .then((resp) => {
        setEditComment(resp.data.data.comment);
      });
  };
  const handleNavigate = (alias) => {
    const path = `/${alias}`;
    Navigate(path);
    axios
      .post(process.env.REACT_APP_BACKEND + `blogs/getone/${alias}`, {
        user_ref: userId,
      })
      .then((resp) => {
        setBlogDetails(resp.data.data.data);
        setTotalComments(resp.data.data.data.no_of_comments);
        setNoOfLikes(resp.data.data.data.no_of_likes);

        axios
          .post(process.env.REACT_APP_BACKEND + "blogs/commentgetall", {
            _id: resp.data.data.data._id,
          })
          .then((resp) => {
            setCommentList(resp.data.data.comments);
          });
      });
  };
  return (
    <div className="container mt-4 pb-4">
      {/* <Search /> */}

      <div>
        <h1>Blog</h1>
        <div className="row">
          <div className="col-md-8">
            {/* <h3>Recent Post</h3> */}
            <Card className="shadow">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="m-3 blogstitle">{blogDetails?.title}</h3>
                  <div>
                    <img src={blogDetails?.thumbnail} width="100%" alt="" />
                  </div>

                  <div className="m-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="mt-2 d-flex">
                        <p className="blogstags ms-4">
                          {moment(blogDetails?.createdAt).format("MMM Do YYYY")}
                        </p>
                        <p className="blogstags ms-3">
                          <i>Written by {blogDetails?.user_ref?.name} </i>
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <IconButton
                          onClick={(e) => handleLike(e, blogDetails?._id)}
                        >
                          {likeStatus !== false ? (
                            <ThumbUpIcon sx={{ color: "#4267b2" }} />
                          ) : (
                            <ThumbUpOffAltIcon />
                          )}
                        </IconButton>
                        <p className="m-0 ">{noOfLikes}</p>
                      </div>
                    </div>

                    {/* <p className="blogsdescp">{blogDetails?.content}</p> */}

                    {/* </div> */}
                  </div>
                  <div className="m-3">
                    <div
                      className="blog-content"
                      dangerouslySetInnerHTML={{ __html: blogDetails?.content }}
                    ></div>
                  </div>
                  <div className="mt-2 d-flex justify-content-between">
                    <div className="d-flex ">
                      <p className="blogstags ms-3">
                        {" "}
                        Comments {totalComments}
                      </p>
                      <p className="blogstags ms-3">SHARES 5</p>
                    </div>
                    <div className="wrapper ">
                      <div className="button">
                        <div className="icon">
                          <ShareIcon />
                        </div>

                        <a
                          href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                          target="_blank"
                        >
                          <FacebookIcon className="iconbtn ms-2" />
                        </a>
                        <a
                          href={`whatsapp://send?text=${window.location.href}`}
                          target="_blank"
                          style={{ color: "#25D366" }}
                        >
                          <WhatsAppIcon
                            className="iconbrn ms-2"
                            style={{ color: "#25D366" }}
                          />
                        </a>
                        <a
                          href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                          target="_blank"
                        >
                          <TwitterIcon className="iconbtn ms-2" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>

            <div className="mt-5">
              <h3>Comments ({totalComments})</h3>
              <div className="row shadow-sm g-0 mt-3  p-0 mb-3">
                <div className="col-11 ps-3 ">
                  <TextField
                    id="outlined-basic"
                    value={comment}
                    variant="standard"
                    placeholder="Add a  Comment"
                    fullWidth
                    onChange={handleComments}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>
                <div className="col-1 text-end ">
                  <IconButton onClick={handleSubmitComment}>
                    <SendIcon sx={{ color: "#01a1de" }} />
                  </IconButton>
                </div>
              </div>

              {commentList.length > 0 ? (
                <div className="d-cm-section  ">
                  {commentList.map((item, index) => (
                    <div className="row mt-4 shadow-sm " key={index}>
                      <div className="col-md-2">
                        <img
                          src={
                            item?.user_ref?.picture !== "" &&
                            item?.user_ref?.picture !== null
                              ? item?.user_ref?.picture
                              : avatar
                          }
                          width="100%"
                          alt=""
                        />
                      </div>
                      <div className="col-md-10">
                        <div className="d-flex align-items-center justify-content-between">
                          <p>{item?.user_ref?.name}</p>
                          {userId === item?.user_ref?._id ? (
                            <>
                              <div className="text-end ">
                                <IconButton
                                  aria-label="delete"
                                  onClick={(e) =>
                                    handleDeleteModal(e, item._id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  onClick={(e) =>
                                    handleEditComments(e, item._id)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p> {moment(item?.createdAt).format("DD MMM YYYY")}</p>
                        {commentId !== item?._id || type !== "edit" ? (
                          <p>{item.comment}</p>
                        ) : (
                          <div className="row shadow-sm mt-3 pt-2 mb-2">
                            <div className="col-md-11 ">
                              <TextField
                                id="outlined-basic"
                                value={editComment}
                                variant="standard"
                                placeholder="Write Comment"
                                fullWidth
                                onChange={handleComments}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </div>
                            <div className="col-md-1 ">
                              <IconButton onClick={handleSubmitComment}>
                                <SendIcon sx={{ color: "#01a1de" }} />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <h3>Trending Posts</h3>

            {recentBlogs.map((item, index) => (
              <div
                key={index}
                className="p-2 mt-2 mb-2 shadow blog-rr-cont"
                style={{
                  backgroundImage: ` linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.73)),url(${
                    item?.thumbnail !== null && item?.thumbnail !== undefined
                      ? item?.thumbnail
                      : "https://wallpaperaccess.com/full/983279.jpg"
                  })`,
                }}
                onClick={() => handleNavigate(item?.alias)}
              >
                {/* <div className="blog-layer"></div> */}
                <div className="blog-rr-cont1">
                  <p className="blog-rr-title">{item?.title}</p>
                  <p className="blogs-rr-date">
                    {moment(item.createdAt).format("DD MMM YYYY")}
                  </p>
                </div>
              </div>
            ))}

            <h3 className="mt-4">Tags</h3>
          </div>
        </div>
      </div>
      {/* --------------------Delete Modal-------------------------- */}
      <Modal
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">Are you Sure you want to delete the Blog?</h4>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "40%", mt: 4, mr: 4 }}
                onClick={() => handleDeleteComments()}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "40%", mt: 4 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BlogItem;
