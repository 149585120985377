import React, { useEffect, useState } from "react";
import Search from "../Search/Search";
import { Card, TextField, Button, Modal } from "@mui/material";
import { NotificationManager } from "react-notifications";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useLocation, useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import SendIcon from "@mui/icons-material/Send";
import moment from "moment";
import Avatar from "../../assets/avatar-raavalo.png";
import Axios from "../../../axios";
import { useNavigate } from "react-router-dom";
const Discussion = () => {
  const navigate = useNavigate();
  const userid = localStorage.getItem("id");
  const [discussionData, setDiscussionData] = useState();
  const [likeStatus, setLikeStatus] = useState(false);
  const [noOfLikes, setNoOfLikes] = useState(false);
  const [comment, setCommet] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [totalComments, setTotalComments] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [type, setType] = useState("");
  const [commentId, setCommetId] = useState("");
  const [editComment, setEditComment] = useState("");
  const [trendingDiscussion, setTrendingDiscussion] = useState([]);
  const [call, setCall] = useState(false);
  useEffect(() => {
    setCall(false);
    axios
      .post(
        process.env.REACT_APP_BACKEND +
          `discussion/getone${window.location.pathname}`,
        { user_ref: userid }
      )
      .then((response) => {
        setDiscussionData(response.data.data.data);
        setLikeStatus(response.data.data.likeStatus);
        setTotalComments(response.data.data.data.no_of_comments);
        setNoOfLikes(response.data.data.data.no_of_likes);

        axios
          .post(process.env.REACT_APP_BACKEND + "discussion/commentgetall", {
            _id: response.data.data.data._id,
          })
          .then((resp) => {
            setCommentList(resp.data.data.comments);
          });
      });
    // Api to get all the comments on load of the page

    //Api to get the trending discussion
    axios
      .get(process.env.REACT_APP_BACKEND + "discussion/latestdiscussions")
      .then((res) => {
        setTrendingDiscussion(res.data.data);
      });
  }, [call]);
  // function For Like a discussion
  const handleLike = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    Axios.post(process.env.REACT_APP_BACKEND + "discussion/like_dislike", {
      _id: id,
      user_ref: userid,
    })
      .then((resp) => {
        setLikeStatus(resp.data.data.likeStatus);
        setNoOfLikes(resp.data.data.data.no_of_likes);
      })
      .catch((error) => {
        NotificationManager.error(error.response.data.message);
      });
  };
  // handle Chnage for comments
  const handleComments = (e) => {
    let val = e.target.value;

    if (type == "edit") {
      setEditComment(val);
    } else {
      setCommet(val);
    }
  };
  // FUNCATION TO ADD COMMENTS
  const handleSubmitComment = () => {
    if (type === "edit") {
      Axios.post(process.env.REACT_APP_BACKEND + "discussion/commentupdate", {
        _id: discussionData?._id,
        comment_id: commentId,
        comment: editComment,
      }).then((resp) => {
        setCommentList(resp?.data?.data?.comments);
        NotificationManager.success(resp?.data?.message);
        setType("");
      });
    } else {
      if (comment !== "" && comment != null && comment !== undefined) {
        Axios.post(process.env.REACT_APP_BACKEND + "discussion/commentadd", {
          _id: discussionData?._id,
          user_ref: userid,
          comment: comment,
        })
          .then((resp) => {
            setCommet("");
            setCommentList(resp?.data?.data?.comments);
            setTotalComments(resp?.data?.data?.no_of_comments);
            NotificationManager.success(resp?.data?.message);
          })
          .catch((error) => {
            NotificationManager.error(error?.response?.data?.message);
          });
      }
    }
  };
  // function to delete the comments
  const handleDeleteComments = () => {
    Axios.post(process.env.REACT_APP_BACKEND + "discussion/commentdelete", {
      _id: discussionData?._id,
      comment_id: deleteId,
    }).then((resp) => {
      setCommentList(resp?.data?.data?.comments);
      setTotalComments(resp?.data?.data?.no_of_comments);
      handleClose();
      NotificationManager.success(resp?.data?.message);
    });
  };
  const handleDeleteModal = (e, id) => {
    setDeleteModal(true);
    setDeleteId(id);
  };
  const handleClose = () => {
    setDeleteModal(false);
  };
  // Api for Get one comments
  const handleEditComments = (e, id) => {
    setType("edit");
    setCommetId(id);
    axios
      .post(process.env.REACT_APP_BACKEND + "discussion/commentgetone", {
        _id: discussionData?._id,
        comment_id: id,
      })
      .then((resp) => {
        setEditComment(resp.data.data.comment);
      });
  };
  const handleTrendingDiscussion = (id) => {
    const path = `/${id}`;
    navigate(path);
    setCall(true);
  };
  return (
    <div className="container pb-4 mt-4">
      {/* <Search /> */}

      <div>
        <h1>Discussion</h1>
        <div className="row">
          <div className="col-md-4">
            <h3>Trending Discussion</h3>
            {trendingDiscussion &&
              trendingDiscussion.map((item, index) => (
                <Card
                  className="p-3 mt-2 cardbg dicuss-card"
                  key={index}
                  onClick={() => handleTrendingDiscussion(item?.alias)}
                >
                  <p className="m-0  discuss-tre-title">{item?.title}</p>
                  <p className="m-3 blogs-rr-date">
                    {moment(item?.createdAt).format("DD MMM YYYY")}
                  </p>
                </Card>
              ))}
            <h3 className="mt-4">Tags</h3>
          </div>
          <div className="col-md-8">
            <div className="row shadow-sm">
              <div className="col-2">
                <img
                  src={
                    discussionData?.user_ref?.picture !== "" &&
                    discussionData?.user_ref?.picture !== null
                      ? discussionData?.user_ref?.picture
                      : Avatar
                  }
                  width="100%"
                  alt=""
                  className="rounded-circle mt-4"
                />
              </div>
              <div className="col-10">
                <>
                  <div className="d-flex align-items-center justify-content-between mt-4">
                    <p>{discussionData?.user_ref?.name}</p>

                    <p>
                      {" "}
                      {moment(discussionData?.updatedAt).format("DD MMM YYYY")}
                    </p>
                  </div>
                  <h3 className="mt-1 blogstitle">{discussionData?.title}</h3>
                  <div>
                    <img
                      src={
                        discussionData?.images !== ""
                          ? discussionData?.images
                          : " "
                      }
                      width="100%"
                      alt=""
                    />
                  </div>
                  <div className="m-3">
                    {/* <div className="mt-2 d-flex">
                      <p className="blogstags ms-4">22 jully 2019</p>
                      <p className="blogstags ms-3">5 Comments</p>
                      <p className="blogstags ms-3">
                        {" "}
                        <span>128</span> SHARES
                      </p>
                    </div> */}
                    <p className="blogsdescp">{discussionData?.content}</p>
                    <div className="row">
                      <div className="col-8"></div>
                      <div className="col-4 text-end">
                        <IconButton
                          onClick={(e) => handleLike(e, discussionData?._id)}
                        >
                          {likeStatus !== false ? (
                            <ThumbUpIcon style={{ color: "01a1de" }} />
                          ) : (
                            <ThumbUpOffAltIcon />
                          )}
                        </IconButton>
                        <span>{noOfLikes}</span>
                      </div>
                    </div>

                    {/* <div className="wrapper ">
                      <div className="button">
                        <div className="icon">
                          <ShareIcon />
                        </div>
                        <GoogleIcon className="iconbtn " />
                        <FacebookIcon className="iconbtn ms-2" />
                        <TwitterIcon className="iconbtn ms-2" />
                        <WhatsAppIcon className="iconbrn ms-2" />
                      </div>
                    </div> */}
                  </div>
                </>
              </div>
            </div>
            {/* -------------------------Comments Section--------------- */}
            <div className="mt-5 ">
              <h3>Comments {totalComments}</h3>
              <div className="row shadow-sm mt-3 mb-4 pt-2">
                <div className="col-md-11 ">
                  <TextField
                    id="outlined-basic"
                    value={comment}
                    variant="standard"
                    placeholder="Add a Comment"
                    fullWidth
                    onChange={handleComments}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </div>
                <div className="col-md-1 text-end">
                  <IconButton onClick={handleSubmitComment}>
                    <SendIcon sx={{ color: "#01a1de" }} />
                  </IconButton>
                </div>
              </div>
              {commentList.length > 0 ? (
                <div className="d-cm-section  ">
                  {commentList.map((item, index) => (
                    <div className="row mt-4 shadow-sm " key={index}>
                      <div className="col-md-2">
                        <img
                          src={
                            item?.user_ref?.picture !== null &&
                            item?.user_ref?.picture !== ""
                              ? item?.user_ref?.picture
                              : Avatar
                          }
                          width="100%"
                        />
                      </div>
                      <div className="col-md-10">
                        <div className="d-flex align-items-center justify-content-between">
                          <p>{item?.user_ref?.name}</p>
                          {userid === item?.user_ref?._id ? (
                            <>
                              <div className="text-end ">
                                <IconButton
                                  aria-label="delete"
                                  onClick={(e) =>
                                    handleDeleteModal(e, item._id)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="edit"
                                  onClick={(e) =>
                                    handleEditComments(e, item._id)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <p> {moment(item?.createdAt).format("DD MMM YYYY")}</p>
                        {commentId !== item?._id || type !== "edit" ? (
                          <p>{item.comment}</p>
                        ) : (
                          <div className="row shadow-sm mt-3 pt-2 mb-2">
                            <div className="col-md-11 ">
                              <TextField
                                id="outlined-basic"
                                value={editComment}
                                variant="standard"
                                placeholder="Write Comment"
                                fullWidth
                                onChange={handleComments}
                                InputProps={{
                                  disableUnderline: true,
                                }}
                              />
                            </div>
                            <div className="col-md-1 ">
                              <IconButton onClick={handleSubmitComment}>
                                <SendIcon sx={{ color: "#01a1de" }} />
                              </IconButton>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------Modal delete------------------- */}
      <Modal
        open={deleteModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modelreview">
          <div className="text-center p-3">
            <h4 className="mt-2">
              Are you Sure you want to delete the Discussion?
            </h4>

            <div className="d-felx">
              <Button
                variant="contained"
                sx={{ width: "40%", mt: 4, mr: 4 }}
                onClick={() => handleDeleteComments()}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                sx={{ width: "40%", mt: 4 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Discussion;
