import { Button, TextField } from "@mui/material";
import React from "react";
import { useState } from "react";
import axios from "axios";
import { NotificationManager } from "react-notifications";

const AddBusiness = () => {
  const [serviceName, setServiceName] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [address, setAddress] = useState("");
  const [remarks, setRemarks] = useState("");
  const [file, setFile] = useState([]);
  const [serviceimg, setServiceImg] = useState([]);
  const [phone, setPhone] = useState("");
  const userId = localStorage.getItem("id");
  const handleChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    if (nam === "serviceName") {
      setServiceName(val);
    } else if (nam === "webUrl") {
      setWebUrl(val);
    } else if (nam === "address") {
      setAddress(val);
    } else if (nam === "phone") {
      setPhone(val);
    } else if (nam === "remarks") {
      setRemarks(val);
    } else if (nam === "uploadFile") {
      let files = Array.prototype.slice.call(e.target.files);

      setFile(files);
    } else if (nam === "serviceImg") {
      let files = Array.prototype.slice.call(e.target.files);
      setServiceImg(files);
    }
  };

  const handleSubmit = () => {
    const formData = new FormData();
    if (
      serviceName !== "" &&
      webUrl !== "" &&
      address !== "" &&
      phone !== "" &&
      remarks !== ""
    ) {
      formData.append("title", serviceName);
      formData.append("website", webUrl);
      formData.append("address", address);
      formData.append("phone_number", phone);
      formData.append("overview", remarks);
      file.map((file) => {
        formData.append("file", file);
      });
      serviceimg.map((file) => {
        formData.append("images", file);
      });
      formData.append("user_ref", userId);
      axios
        .post(process.env.REACT_APP_BACKEND + "services/create", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          NotificationManager.success(resp?.data?.message);
          setServiceName("");
          setWebUrl("");
          setAddress("");
          setRemarks("");
          setFile([]);
          setServiceImg([]);
          setPhone("");
        });
    }
  };
  return (
    <>
      <div className="container">
        <h2 className="mt-5 mb-4">Add Your Business In Revaalo</h2>
        <div className="mt-2 mb-2">
          <p className="m-0">Product/Service Name *</p>
          <TextField
            required
            id="outlined-required"
            name="serviceName"
            placeholder="Product/Service Name "
            value={serviceName}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div className="mt-2 mb-2">
          <p className="m-0">Website URL *</p>
          <TextField
            required
            id="outlined-required"
            onChange={handleChange}
            name="webUrl"
            placeholder="Website URL"
            value={webUrl}
            fullWidth
          />
        </div>
        <div className="mt-2 mb-2">
          <p className="m-0">Phone Number *</p>
          <TextField
            required
            id="outlined-required"
            onChange={handleChange}
            name="phone"
            placeholder="phone"
            value={phone}
            fullWidth
          />
        </div>
        <div className="mt-2 mb-2">
          <p className="m-0">Address **</p>
          <TextField
            required
            id="outlined-multiline-static"
            multiline
            placeholder="Address "
            maxRows={4}
            onChange={handleChange}
            name="address"
            value={address}
            fullWidth
          />
        </div>
        <div className="mt-2 mb-2">
          <p className="m-0">
            Upload a file with the details of product/service to be listed
          </p>
          <Button variant="contained" component="label">
            Upload
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              name="uploadFile"
              onChange={handleChange}
            />
          </Button>
          {file.map((file) => (
            <span className="ms-3">{file.name}</span>
          ))}
        </div>
        <div className="mt-2 mb-2">
          <p className="m-0">Upload Product/Service Images</p>
          <Button variant="contained" component="label">
            Upload
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              name="serviceImg"
              onChange={handleChange}
            />
          </Button>
          {serviceimg.map((file) => (
            <span className="ms-3">{file.name} </span>
          ))}
        </div>
        <div className="mt-2 mb-2">
          <p>Remarks</p>
          <TextField
            required
            placeholder="Remarks"
            id="outlined-required"
            name="remarks"
            value={remarks}
            onChange={handleChange}
            fullWidth
          />
        </div>
        <div className="mt-2 mb-2">
          <p className="m-0">Tags</p>
          <TextField required id="outlined-required" fullWidth />
        </div>
        <div className="text-end mt-3 mb-5">
          <Button variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddBusiness;
