import React, { useState, useEffect } from "react";

import axios from "axios";
import Pagination from "@mui/material/Pagination";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Filters from "./filters";
import { Link, useLocation, useParams } from "react-router-dom";
import "./style.css";
import GoogleAds from "../../../GoogleAds";
import Search from "../Search/Search";
import loaderImg from "../../assets/loader.gif";
const ServiceList = () => {
  const [expand, setExpand] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const { state } = useLocation();
  const { slug, city } = useParams();
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [area, setArea] = useState("");
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (
      state &&
      state.cityArea &&
      state.cityArea.areaName !== null &&
      state.cityArea.areaName !== undefined &&
      state.cityArea.areaName !== ""
    ) {
      setLoader(true);
      axios
        .post(
          process.env.REACT_APP_BACKEND +
            `urlalias/listing/${state.cityArea.areaName}/${state.city_name}/${slug}`
        )
        .then((res) => {
          setServiceList(res.data.data.data);
          setCount(res.data.data.pages);
          setLoader(false);
        });
    } else if (
      state?.inp !== null &&
      state?.inp !== undefined &&
      state?.inp !== ""
    ) {
      setLoader(true);
      axios
        .post(process.env.REACT_APP_BACKEND + `urlalias/listing/${slug}`, {
          inp: state?.inp,
        })
        .then((res) => {
          setServiceList(res.data.data.data);
          setCount(res.data.data.pages);
          setLoader(false);
        });
    } else {
      setLoader(true);
      axios
        .post(
          process.env.REACT_APP_BACKEND +
            `urlalias/listing${window.location.pathname}`
        )
        .then((res) => {
          setServiceList(res.data.data.data);
          setCount(res.data.data.pages);
          setLoader(false);
        });
    }
  }, [slug, city, state]);
  // const scrollToTop = () => {
  //   const divElement = document.getElementById("list");
  //   divElement.scrollIntoView({
  //     top: 0,
  //     behavior: "smooth" /*or smooth*/,
  //   });
  // };

  const handleExpand = () => {
    setExpand((prev) => !prev);
  };

  const handleChange = (e, p) => {
    setLoader(true);
    setPage(p);
    const data = {
      limit: 10,
      page: p,
    };
    axios
      .post(
        process.env.REACT_APP_BACKEND +
          `urlalias/listing${window.location.pathname}`,
        data
      )
      .then((res) => {
        setServiceList(res.data.data.data);
        // scrollToTop();
        setLoader(false);
        window.scroll(0, 0);
      });
  };

  const handleAreaFilters = (val) => {
    setArea(val);
    const data = {
      category: category,
      brand: brand,
      city: city,
      area: val,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "filters/filter", data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const handleBrandFilters = (val) => {
    setBrand(val);
    const data = {
      category: category,
      brand: val,
      city: city,
      area: area,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "filters/filter", data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCategoryFilters = (val) => {
    setCategory(val);
    const data = {
      category: val,
      brand: brand,
      city: city,
      area: area,
    };
    axios
      .post(process.env.REACT_APP_BACKEND + "filters/filter", data)
      .then((res) => {
        // console.log("cat filter result", res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* -----------------------------------List with pagenation-------------------------------------- */}
        <div className="col-md-9 pd-9">
          <div className="mb-4">
            <div className="p-search">
              <Search />
            </div>
            {loader !== true ? (
              <>
                {serviceList.map((service, index) => {
                  return (
                    <Link to={`/${service.slug}`} className="Link" key={index}>
                      <div
                        className="card-body row d-flex shadow-sm  p-4 mb-4 "
                        id="list"
                        key={service._id}
                      >
                        <div className="col-md-1 col-sm-12 st-center">
                          <img
                            src={
                              service?.images.length > 0
                                ? service?.images[0]
                                : service.category_ref.images
                            }
                            alt=""
                            // width="50%"
                            className="serviceimg"
                          />
                        </div>
                        <div className=" col-md-11">
                          <div className="d-flex flex-wrap justify-content-between sd-media">
                            <div>
                              <h6 className="service_title">
                                {" "}
                                {service.title}
                              </h6>

                              <p className="d-flex align-items-center">
                                <LocationOnIcon />
                                {service &&
                                service.area_ref &&
                                service.area_ref.areaName !== null &&
                                service.area_ref.areaName !== undefined ? (
                                  <>{service?.area_ref?.areaName},</>
                                ) : (
                                  ""
                                )}

                                {service?.city_name}
                              </p>
                            </div>
                            <div className="text-center ">
                              <p className="service-rating">
                                {service.avg_rating !== null
                                  ? service.avg_rating
                                  : 0}
                              </p>
                              <p className="service-review">
                                {service.no_of_reviews !== null
                                  ? service.no_of_reviews
                                  : 0}{" "}
                                Reviews
                              </p>
                            </div>
                          </div>
                          <div className="ms-2" style={{ fontSize: "14px" }}>
                            <p className="sl-decp">{service.overview}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
                <div className="d-flex justify-content-center mt-3 mb-4">
                  <Pagination
                    count={count}
                    page={page}
                    variant="outlined"
                    onChange={handleChange}
                    shape="rounded"
                    color="primary"
                  />
                </div>
              </>
            ) : (
              <div className="text-center mt-4">
                <img src={loaderImg} width="20%" alt="" srcset="" />{" "}
              </div>
            )}
          </div>
        </div>
        {/* -----------------------------------------Maps start here----------------------------------------- */}
        <div className="col-md-3 pe-5">
          <section className="location mt-4 card p-1">
            <iframe
              title="Maps"
              className="embed-responsive-item location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d497699.99742217135!2d77.35072793724844!3d12.953847708182487!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sBengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1626291694647!5m2!1sen!2sin"
            ></iframe>
          </section>
          {/* <Filters
            cityRef={location.state}
            handleFilter={handleAreaFilters}
            brandFilters={handleBrandFilters}
            categoryFilters={handleCategoryFilters}
            slug={slug}
          /> */}
          <div>
            <GoogleAds slot="1355260279" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceList;
